import {
  IconTwitter,
  IconInstagram,
  IconTelegram,
  IconLinkedin,
} from './icons';
import styled from '@emotion/styled';
import { Icon } from './styles';

const Container = styled.div<{ large?: boolean; }>(({ theme, large }) => ({
  display: 'flex',
  border: '1px solid',
  borderRadius: theme.radius[large ? 'medium' : 'default'],
  lineHeight: theme.font.lineHeight.compact,
}));

const SocialIcon = styled.a<{ large?: boolean; }>(({ large }) => ({
  display: 'flex',
  alignItems: 'center',
  borderLeft: '1px solid',
  padding: '0 1em',
  margin: '.25em 0',
  fontSize: `${large ? 2 : 1}rem`,
  [`${Icon}`]: {
    margin: 0,
  },
  '&:last-of-type': { border: 'none' },
}));

const links = [
  {
    name: 'linkedin',
    icon: <IconLinkedin />,
    href: 'https://www.linkedin.com/company/filmnetir/',
  },
  {
    name: 'twitter',
    icon: <IconTwitter />,
    href: 'https://twitter.com/filmnetofficial',
  },
  {
    name: 'telegram',
    icon: <IconTelegram />,
    href: 'https://t.me/filmnetofficial',
  },
  {
    name: 'instagram',
    icon: <IconInstagram />,
    href: 'https://instagram.com/filmnet.ir_',
  },
];

export default function SocialLinks({ large }: { large?: boolean; }) {
  return (
    <Container large={large}>
      {links.map(({ name, href, icon }) => (
        <SocialIcon key={name} href={href} large={large}>
          <Icon cursor="pointer">{icon}</Icon>
        </SocialIcon>
      ))}
    </Container>
  );
}

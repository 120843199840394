import React from 'react';

export default function IconScheduleTable() {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 0.333333C14 0.149238 14.1492 0 14.3333 0H15.6667C15.8508 0 16 0.149238 16 0.333333V1.66667C16 1.85076 15.8508 2 15.6667 2H14.3333C14.1492 2 14 1.85076 14 1.66667V0.333333Z" fill="white" />
      <path d="M14 6.33333C14 6.14924 14.1492 6 14.3333 6H15.6667C15.8508 6 16 6.14924 16 6.33333V7.66667C16 7.85076 15.8508 8 15.6667 8H14.3333C14.1492 8 14 7.85076 14 7.66667V6.33333Z" fill="white" />
      <path d="M14 12.3333C14 12.1492 14.1492 12 14.3333 12H15.6667C15.8508 12 16 12.1492 16 12.3333V13.6667C16 13.8508 15.8508 14 15.6667 14H14.3333C14.1492 14 14 13.8508 14 13.6667V12.3333Z" fill="white" />
      <path d="M0 0.333333C0 0.149238 0.149238 0 0.333333 0H11.6667C11.8508 0 12 0.149238 12 0.333333V1.66667C12 1.85076 11.8508 2 11.6667 2H0.333333C0.149239 2 0 1.85076 0 1.66667V0.333333Z" fill="white" />
      <path d="M0 6.33333C0 6.14924 0.149238 6 0.333333 6H11.6667C11.8508 6 12 6.14924 12 6.33333V7.66667C12 7.85076 11.8508 8 11.6667 8H0.333333C0.149239 8 0 7.85076 0 7.66667V6.33333Z" fill="white" />
      <path d="M0 12.3333C0 12.1492 0.149238 12 0.333333 12H11.6667C11.8508 12 12 12.1492 12 12.3333V13.6667C12 13.8508 11.8508 14 11.6667 14H0.333333C0.149239 14 0 13.8508 0 13.6667V12.3333Z" fill="white" />
    </svg>
  );
}

import React from 'react';
import styled, { CSSObject } from '@emotion/styled';
import { responsiveProps, SpacingIndex } from 'common/theme';
import { deepMerge } from 'common/utils';

const paddingPropKeys = ['p', 'px', 'py', 'pt', 'pl', 'pb', 'pr'] as const;
const marginPropKeys = ['m', 'mx', 'my', 'mt', 'ml', 'mb', 'mr'] as const;
const spacingPropKeys = [...paddingPropKeys, ...marginPropKeys];
type PaddingPropsKey = typeof paddingPropKeys[number];
type MarginPropsKey = typeof marginPropKeys[number];
type SpacingPropsKey = PaddingPropsKey | MarginPropsKey;
export type SpacingProps = Partial<Record<SpacingPropsKey, responsiveProps<SpacingIndex | string> | undefined>>;

type SpacingCssProperties = 'margin' | 'marginLeft' | 'marginTop' | 'marginRight' | 'marginBottom' |
  'padding' | 'paddingLeft' | 'paddingRight' | 'paddingTop' | 'paddingBottom';

const spacingMap: Record<SpacingPropsKey, SpacingCssProperties[]> = {
  m: ['margin'],
  mx: ['marginLeft', 'marginRight'],
  my: ['marginTop', 'marginBottom'],
  mb: ['marginBottom'],
  ml: ['marginLeft'],
  mt: ['marginTop'],
  mr: ['marginRight'],
  p: ['padding'],
  px: ['paddingLeft', 'paddingRight'],
  py: ['paddingTop', 'paddingBottom'],
  pt: ['paddingTop'],
  pl: ['paddingLeft'],
  pb: ['paddingBottom'],
  pr: ['paddingRight'],
};

export default function withSpacingStyles<T extends React.ComponentType<React.ComponentProps<T>>>(Component: T) {
  return styled(Component)<SpacingProps>((props) => {
    const { theme } = props;
    const styles: CSSObject = {};
    spacingPropKeys.forEach((key) => {
      if (!key || props[key] === undefined) return;
      deepMerge(styles, theme.mixins.create(props[key], (v) => {
        if (v === undefined) return {};
        const addedStyles: Partial<Record<SpacingCssProperties, string | undefined>> = {};
        spacingMap[key].forEach((x) => {
          addedStyles[x] = typeof v === 'number' ? theme.spacing(v) : v;
        });
        return addedStyles;
      }));
    });
    return styles;
  });
}

import React from 'react';
import { CategoryList } from './apiService';
import { __t } from './i18n';
import { Flag, Flex } from './styles';
import { SpacingProps } from './withStyleSystem/withSpacingStyles';

type CountryYearProps = {
  year?: number,
  categories?: CategoryList[],
  label?: boolean,
  textOnly?: boolean,
  limit?: number,
} & SpacingProps;

export default function CountryYear({ year, categories, label, textOnly, limit, ...styleSystem }: CountryYearProps) {
  const countries = categories?.find((item) => item.type === 'territory')?.items;
  const filteredCountries = countries?.slice(0, limit);
  const countriesString = filteredCountries?.map((country) => country?.title).join('، ').trim();

  if (textOnly) return <>{year ? year + ' /' : ''}  {countriesString}</>;

  return (
    <Flex {...styleSystem}>
      {label && <>{__t('content.country_year')}:&nbsp;</>}
      <Flag>{year} {countriesString}</Flag>
    </Flex>
  );
}

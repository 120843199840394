export default function IconTelegram() {
  return (
    <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 1L14.8769 15L7.68689 11.2081L17.9994 1L10.9153 3.87578L1 7.90262L4.8024 9.99487L6.54194 15"
        stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
      />
    </svg>

  );
}

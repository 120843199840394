import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Button, Flex, Link } from '../styles';
import Icon from '../styles/Icon';
import { IconClose, IconDownloadApp, IconTvWeb } from '../icons';
import { __t } from '../i18n';
import { useAgent, useUser } from '../CacheProvider';

const StyledWrapper = styled.div(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  height: '6rem',
  backgroundColor: theme.palette.background[600][0],
  zIndex: 1300,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1rem',
  [theme.breakpoints.down('lg')]: {
    height: '4rem',
  },
}));

const AppBtn = styled(Button)<{ target?: string }>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '15.125rem',
  height: '4rem',
  marginLeft: '1.5rem',
  backgroundColor: theme.palette.foreground[700][0],
  color: theme.palette.background[800][0],
  '&:hover': {
    backgroundColor: theme.palette.foreground[700][0],
  },
  [theme.breakpoints.down('lg')]: {
    width: '10rem',
    height: '2.5rem',
  },
}));

const TvBtn = styled(Button)<{ target?: string }>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '20.875rem',
  height: '4rem',
  border: '2px solid',
  paddingRight: '0.5rem',
  borderColor: theme.palette.foreground[800][0],
  [theme.breakpoints.down('lg')]: {
    width: '13.75rem',
    height: '2.5rem',
  },
}));

const NoticeText = styled.span(({ theme }) => ({
  fontSize: '1.5rem',
  [theme.breakpoints.down('lg')]: {
    fontSize: '0.875rem',
  },
}));

const AppText = styled.span(({ theme }) => ({
  fontSize: '1.5rem',
  marginRight: '1rem',
  [theme.breakpoints.down('lg')]: {
    fontSize: '1rem',
    marginRight: '0.25rem',
  },
}));

const TvText = styled.span(({ theme }) => ({
  fontSize: '1.5rem',
  marginRight: '1rem',
  [theme.breakpoints.down('lg')]: {
    fontSize: '1rem',
    marginRight: '0.25rem',
  },
}));

const CloseBtn = styled(Button)(({ theme }) => ({
  padding: '0.5rem',
  [theme.breakpoints.down('lg')]: {
    transform: 'scale(0.7)',
  },
}));

const isTv = (userAgent: string): boolean => {
  const tvRegex = /(SmartTV|SMART-TV|TV Browser|TV|BRAVIA|AppleTV|SmartHub|HbbTV|Tizen|WebOS|Android TV|GoogleTV|Andr0id)/i;
  const androidRegex = /(Android)/i;
  const mobileRegex = /(Mobile|Tab)/i;

  const isTv = tvRegex.test(userAgent);
  const isAndroid = androidRegex.test(userAgent);

  if (isTv) {
    return isTv;
  } else if (isAndroid) {
    return !mobileRegex.test(userAgent);
  }
  return false;
};

const isAndroidTv = (userAgent: string): boolean => {
  const tvRegex = /(Android|Andr0id|GoogleTV)/i;
  return tvRegex.test(userAgent);
};

const TvNotice = () => {
  const { loaded } = useUser();
  const { isTvAgent, updateAgent } = useAgent();

  const [isAndroid, setIsAndroid] = useState(false);

  useEffect(() => {
    loaded && window && handleUserAgent(window.navigator.userAgent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);
  const handleUserAgent = (ua: string) => {
    updateAgent(isTv(ua));
    setIsAndroid(isAndroidTv(ua));
  };

  const handleClose = (e) => {
    e.preventDefault();
    updateAgent(false);
  };

  if (!isTvAgent) return <></>;
  return <StyledWrapper>
    <Flex align="center">
      <CloseBtn onClick={handleClose}>
        <Icon cursor='pointer' size='medium'>
          <IconClose/>
        </Icon>
      </CloseBtn>

      <NoticeText>
        {__t('static.tv.notice')}
      </NoticeText>
    </Flex>

    <Flex align="center">
      {isAndroid && <AppBtn as={Link} href='https://tv.filmnet.ir/apps'>
        <Icon cursor='pointer' size="large">
          <IconDownloadApp/>
        </Icon>
        <AppText>
          {__t('static.tv.downloadApp')}
        </AppText>
      </AppBtn>}

      <TvBtn as={Link} href='https://tv.filmnet.ir'>
        <Icon cursor='pointer' size="semiLarge">
          <IconTvWeb/>
        </Icon>
        <TvText>
          {__t('static.tv.useTvWeb')}
        </TvText>
      </TvBtn>
    </Flex>
  </StyledWrapper>;
};

export default TvNotice;

import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { __t } from './i18n';
import TooltipStatic from './TooltipStatic';

const SubscriptionTooltip = ({ days }: { days: number }) => {
  const [open, setOpen] = useState<boolean>(true);
  const router = useRouter();

  useEffect(() => {
    getTimeDiff();
  }, []);

  const closeTooltip = () => {
    setOpen(false);
    const new_date = new Date();
    localStorage.setItem('subscriptionAlertClosedTime', String(new_date));
  };

  const getTimeDiff = () => {
    const storedAlertClosedTime = localStorage.getItem('subscriptionAlertClosedTime');
    const preDate = storedAlertClosedTime ? new Date(storedAlertClosedTime as string).getTime() : new Date().getTime();
    const currentDate = new Date().getTime();
    const dif = Math.round(((currentDate - preDate) / 60000));
    if (!storedAlertClosedTime) {
      setOpen(true);
    } else if (dif < 720) {
      setOpen(false);
    } else if (dif >= 720) {
      setOpen(true);
    }
  };

  if (router.asPath.includes('/packages') || router.asPath.includes('/tickets')) return null;

  return (
    <TooltipStatic open={open} onCloseEvent={() => closeTooltip()}
      bgNumber={
        (days <= 0) ? 100 : 200} >
      {days <= 0 ? __t('subscription.notHave') : __t('subscription.lessFive', { days })}
    </TooltipStatic>
  );
};

export default SubscriptionTooltip;

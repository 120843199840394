import React from 'react';

export default function IconFilledBookmark() {
  return (
    <svg width="24" height="30" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M-0.000488281 3C-0.000488281 1.34315 1.34266 0 2.99951 0H20.9995C22.6564 0 23.9995 1.34315 23.9995 3V26.6719C23.9995 29.0102 21.4431 30.4496 19.4438 29.237L13.5552 25.6656C12.5991 25.0858 11.3999 25.0858 10.4438 25.6656L4.55523 29.237C2.55593 30.4496 -0.000488281 29.0102 -0.000488281 26.6719V3Z"
        fill="currentColor"
      />
    </svg>
  );
}

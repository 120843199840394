import styled from '@emotion/styled';
import Image from './Image';

const CardImage = styled(Image)(() => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  objectFit: 'cover',
}));

export default CardImage;

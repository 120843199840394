import styled from '@emotion/styled';

const Image = styled.img<{
  round?: boolean,
  width?: string,
  height?: string,
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down',
  maxWidth?: string,
}>(({ theme, round, width, maxWidth = '100%', height, objectFit = 'contain' }) => ({
  width,
  maxWidth,
  height,
  display: 'block',
  objectFit,
  borderRadius: round ? '50%' : theme.radius.default,
}));

export default Image;

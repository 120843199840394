import React, { CSSProperties, MouseEvent } from 'react';
import styled from '@emotion/styled';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import withStyleSystem, { SpacingProps } from 'common/withStyleSystem';

type AnchorProps = {
  block?: boolean;
  disabled?: boolean;
  onClick?: (e: MouseEvent) => void;
  onMouseEnter?: (e: MouseEvent) => void;
  onMouseLeave?: (e: MouseEvent) => void;
  children?: React.ReactNode;
  className?: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
  title?: string;
  style?: CSSProperties;
} & SpacingProps;

const Anchor = withStyleSystem(
  styled.a<AnchorProps>(({ theme, block, disabled }) => ({
    display: block ? 'block' : 'inline-block',
    fontFamily: theme.font.family.default,
    color: theme.palette.foreground[900][0],
    '&:focus': !disabled && {
      outline: 'none',
      ...theme.mixins.focus,
    },
  })),
);

type LinkProps = NextLinkProps & AnchorProps;

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(function Link(
  { href, prefetch = false, replace, scroll, shallow, locale, as, ...anchorProps },
  ref,
) {
  return (
    <NextLink passHref {...{ href, prefetch, replace, scroll, shallow, locale, as }}>
      <Anchor ref={ref} {...anchorProps}/>
    </NextLink>
  );
});

export default Link;

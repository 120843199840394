import { Text, Icon, Link, Divider, NavListItem, Button } from './styles';
import { IconAngleLeft, IconLogout } from './icons';
import { User, logout } from 'common/apiService';
import { actionLogout, useDispatch } from 'common/CacheProvider';
import { useState } from 'react';
import { __t } from './i18n';
import styled from '@emotion/styled';
import NavList, { NavLink } from './NavList';
import SubscriptionStatus from './SubscriptionStatus';
import Dialog from './Dialog';
import { useRouter } from 'next/router';
import ProfilePicker from './Header/ProfilePicker';
import UserAvatar from './Header/UserAvatar';

type ProfileMenuProps = {
  user?: User | null,
  links: NavLink[],
  showAvatar?: boolean,
  showStatus?: boolean,
  isPopup?: boolean,
  showNavStatus?: boolean,
};

const AvatarWrapper = styled(Link)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
}));

const UserNameWrapper = styled.div(() => ({
  flexGrow: 1,
}));

const LogoutLink = styled(NavListItem)(({ theme }) => ({
  ...theme.font.responsiveSize(1),
}));

const ProfileMenu = ({ user, links, showAvatar, showStatus, isPopup = false, showNavStatus = false }: ProfileMenuProps) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const handleLogout = () => {
    const { redirect } = router.query;
    const loginUrl = redirect ? redirect : router.asPath;
    const f = () => dispatch(actionLogout());
    logout().then(f, f).then(() => router.push(`/login?redirect=${loginUrl}`));
  };

  return (
    <>
      <AvatarWrapper href="/user">
        {showAvatar && <UserAvatar user={user} size="large" hasMargin />}
        <UserName user={user} isPopup={isPopup} />
        {!isPopup && <Icon size="large" className="mobile-only"><IconAngleLeft /></Icon>}
      </AvatarWrapper>

      {showStatus && <SubscriptionStatus />}
      {!showNavStatus && <>
        <Divider my={2} />
        <ProfilePicker />
      </>}
      <Divider my={2} />
      <NavList links={links} />
      {showAvatar && <Divider />}
      {!user?.is_sso_login ? <LogoutLink href="#" onClick={(e) => {
        e.preventDefault();
        setOpen(true);
      }}>
        <Icon size="large" ml={1} cursor="pointer"><IconLogout /></Icon>
        {__t('auth.logout')}
      </LogoutLink> : <LogoutLink href="#" onClick={(e) => {
        e.preventDefault();
        window.location.replace('https://campaigns.tapsi.cab/landings/superapp/filmnet');
      }}>
        <Icon size="large" ml={1} cursor="pointer"><IconLogout /></Icon>
        {__t('sso.back_link')}
      </LogoutLink>}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Text variant="body1" align="center" mb={6}>{__t('auth.logout.confirm')}</Text>
        <Button onClick={handleLogout} fullWidth variant="primaryA">{__t('auth.logout')}</Button>
      </Dialog>
    </>
  );
};

type UserNameProps = {
  user: ProfileMenuProps['user'];
  isPopup: ProfileMenuProps['isPopup'];
}

const UserName = ({ user, isPopup }: UserNameProps) => (
  <UserNameWrapper>
    {isPopup && (
      <Text variant="body2" align="center">
        {(user?.name || user?.family)
          ? `${user?.name || ''} ${user?.family || ''}`.trim()
          : user?.msisdn
        }
      </Text>
    )}
    {!isPopup && (
      <>
        <Text variant="body2" mb={{ xs: 0, md: 1 }}>{user?.name} {user?.family}</Text>
        <Text variant="body2" color="weak">{user?.msisdn}</Text>
      </>
    )}
  </UserNameWrapper>
);

export default ProfileMenu;

import styled from '@emotion/styled';
import { Placement, PositioningStrategy } from '@popperjs/core';
import React, { CSSProperties, useState } from 'react';
import { usePopper } from 'react-popper';
import Portal from './Portal';
import ClickAwayListener from 'react-click-away-listener';
import { ColorKey, Radius } from './theme';
import withStyleSystem from './withStyleSystem';
import { useForkRef } from './utils';
import { CSSTransition } from 'react-transition-group';
import { notificationColors } from './AlertService/AlertServiceNotification';
import { NotificationType } from './AlertService/AlertService';

const arrowSize = 8;
const translateAmount = '1em';
const translateMap = {
  top: `translateY(${translateAmount})`,
  'top-start': `translateY(${translateAmount})`,
  'top-end': `translateY(${translateAmount})`,
  bottom: `translateY(-${translateAmount})`,
  'bottom-start': `translateY(-${translateAmount})`,
  'bottom-end': `translateY(-${translateAmount})`,
  right: `translateX(-${translateAmount})`,
  left: `translateX(${translateAmount})`,
};

const Wrapper = styled.div(({ theme }) => ({
  zIndex: theme.zIndex.tooltip,
  fontFamily: theme.font.family.default,
  ...theme.font.responsiveSize(1),
  direction: theme.direction,
  [`&[data-popper-placement^="top"] > ${PopperArrow}`]: {
    bottom: -arrowSize / 2,
  },
  [`&[data-popper-placement^="bottom"] > ${PopperArrow}`]: {
    top: -arrowSize / 2,
  },
  [`&[data-popper-placement^="right"] > ${PopperArrow}`]: {
    left: -arrowSize / 2,
  },
  [`&[data-popper-placement^="left"] > ${PopperArrow}`]: {
    right: -arrowSize / 2,
  },
}));

const PopperPaper = withStyleSystem(styled.div<{
  colorInfo?: [ColorKey, number];
  radius?: keyof Radius;
  placement?: string;
  passive?: boolean;
}>(({ theme, radius, placement, colorInfo, passive }) => {
  const [colorKey, intensity] = colorInfo || [];
  const [backgroundColor, color] = (colorKey && intensity) ? theme.palette[colorKey][intensity] : [];
  const borderRadius = radius && theme.radius[radius];
  const transform = placement && translateMap[placement];

  return ({
    pointerEvents: passive ? 'none' : undefined,
    backgroundColor,
    color,
    borderRadius,
    boxShadow: '0 0 1rem rgba(0,0,0,.3)',
    [`& + ${PopperArrow}, & + ${PopperArrow}::before`]: {
      backgroundColor,
    },
    '.popper-enter &': {
      opacity: 0,
      transform,
    },
    '.popper-enter-active &': {
      opacity: 1,
      transform: 'translateY(0)',
      transition: 'transform .3s ease, opacity .3s ease',
    },
    '.popper-exit &': {
      opacity: 1,
    },
    '.popper-exit-active &': {
      opacity: 0,
      transform,
      transition: 'transform .3s ease, opacity .3s ease',
    },
  });
}));

export type PopperProps = {
  anchor: null | Element,
  children?: React.ReactNode,
  placement?: Placement,
  strategy?: PositioningStrategy,
  className?: string,
  disablePortal?: boolean,
  open?: boolean,
  showArrow?: boolean,
  offsetX?: number,
  offsetY?: number,
  paperRef?: React.Ref<HTMLDivElement>,
  boundary?: HTMLElement,
  type?: NotificationType,
  onClickAway?(e: Event): void,
  wrapperStyles?: CSSProperties,
  passive?: boolean,
  disableMobileHover?: boolean,
} & React.ComponentPropsWithoutRef<typeof PopperPaper>;

const PopperArrow = styled.div(() => ({
  '.popper-enter &::before': {
    transform: 'rotate(45deg) scale(0)',
    opacity: 0,
  },
  '.popper-enter-active &::before': {
    opacity: 1,
    transform: 'rotate(45deg) scale(1)',
    transition: 'transform .4s ease, opacity .4s ease',
  },
  '.popper-exit &::before': {
    transform: 'rotate(45deg) scale(1)',
  },
  '.popper-exit-active &::before': {
    opacity: 0,
    transform: 'rotate(45deg) scale(0)',
    transition: 'transform .4s ease, opacity .1s ease',
  },
  '&::before, &': {
    position: 'absolute',
    width: arrowSize,
    height: arrowSize,
  },
  visibility: 'hidden',
  '&::before': {
    visibility: 'visible',
    content: '""',
    transform: 'rotate(45deg)',
  },
}));

const Popper = React.forwardRef<HTMLDivElement, PopperProps>(({
  anchor = null,
  children,
  open,
  placement,
  strategy,
  disablePortal,
  showArrow,
  offsetX = 0,
  offsetY = 0,
  onClickAway: handleClickAway,
  paperRef,
  boundary,
  wrapperStyles,
  passive,
  type = 'default',
  ...rest
}, ref) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const handleRef = useForkRef<HTMLDivElement>(setPopperElement, ref);
  const {
    styles,
    attributes,
    // state,
  } = usePopper(anchor, popperElement, {
    placement,
    strategy,
    modifiers: [
      showArrow ? { name: 'arrow', options: { element: arrowElement, padding: 5 } } : {},
      { name: 'offset', options: { offset: [offsetX, showArrow ? arrowSize + offsetY : offsetY] } },
      { name: 'preventOverflow', options: { altAxis: true, boundary } },
      { name: 'eventListeners', options: { scroll: false } },
    ],
  });

  const colorInfo = notificationColors[type];

  const mainRender = ({ className: cs }: { className?: string; }) => {
    const popper = (
      <Wrapper
        className={cs}
        ref={handleRef}
        style={{ ...(wrapperStyles ?? {}), ...styles.popper }}
        {...attributes.popper}
      >
        <PopperPaper {...rest} colorInfo={colorInfo} ref={paperRef} passive={passive} placement={attributes?.popper?.['data-popper-placement'] || placement}>
          {children}
        </PopperPaper>
        {showArrow && <PopperArrow data-popper-arrow ref={setArrowElement} style={styles.arrow} />}
      </Wrapper>
    );

    return (
      handleClickAway
        ? (
          <ClickAwayListener onClickAway={handleClickAway}>
            {popper}
          </ClickAwayListener>
        )
        : popper
    );
  };

  return (
    <Portal disablePortal={disablePortal}>
      <CSSTransition classNames="popper" timeout={300} in={(!!anchor && (open ?? true))} unmountOnExit>
        {mainRender({})}
      </CSSTransition>
    </Portal>
  );
});

export default Popper;

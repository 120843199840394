import styled from '@emotion/styled';
import Text from './Text';

const EllipsisText = styled(Text)<{ line?: number, maxWidth?: string }>(({ line = 1, maxWidth }) => ({
  display: '-webkit-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: line,
  whiteSpace: 'pre-wrap',
  maxWidth,
}));

export default EllipsisText;

import { useEffect } from 'react';

export default function useScrollLock(trigger: boolean) {
  useEffect(() => {
    if (trigger) {
      const header = document.getElementById('main-menu');
      const body = document.body;
      if (!body || !header) return;

      const offset = (window.innerWidth - body.clientWidth) + 'px';

      body.style.paddingRight = offset;
      header.style.paddingRight = offset;
      body.style.overflow = 'hidden';
      body.style.height = '100%';

      return () => {
        body.style.paddingRight = '';
        header.style.paddingRight = '';
        body.style.overflow = '';
        body.style.height = '';
      };
    }
  }, [trigger]);
}

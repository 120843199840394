import styled from '@emotion/styled';
import theme, { BreakpointKeys, SpacingIndex } from 'common/theme';
import withStyleSystem from 'common/withStyleSystem';

type PaperProps = {
  background?: 'main' | 'weak' | 'strong',
  padding?: SpacingIndex,
  borderRadius?: string,
  width?: BreakpointKeys,
  maxWidth?: BreakpointKeys,
};

export const paperBg: Record<string, keyof typeof theme.palette.background> = {
  main: 700,
  weak: 600,
  strong: 500,
};

const Paper = styled.section<PaperProps>(({ theme, background = 'main', borderRadius = 'default', width, maxWidth }) => {
  const intensity = paperBg[background];
  const [bg, text] = theme.palette.background[intensity];

  return ({
    backgroundColor: bg,
    color: text,
    padding: theme.spacing(2),
    borderRadius: theme.radius[borderRadius],
    maxWidth: maxWidth && theme.breakpoints[maxWidth],
    width: width && theme.breakpoints[width],
  });
});

export default withStyleSystem(Paper);

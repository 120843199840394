import styled from '@emotion/styled';
import { IconProfileAvatar } from 'common/icons';
import withStyleSystem from 'common/withStyleSystem';
import { SpacingProps } from 'common/withStyleSystem/withSpacingStyles';
import CardImage from './CardImage';
import Link from './Link';

type AvatarWrapperProps = {
  size?: string;
  href?: string;
  placeholder?: string;
};

export const AvatarWrapper = withStyleSystem(
  styled.div<AvatarWrapperProps>(({ theme, placeholder, size = '9.875rem' }) => ({
    position: 'relative',
    borderRadius: theme.radius.pill,
    width: size,
    minWidth: size,
    height: size,
    overflow: 'hidden',
    backgroundImage: placeholder && `url(${placeholder})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  })),
);

const AvatarLink = styled(Link)(({ theme }) => ({
  '&:hover, &:focus': {
    borderRadius: theme.radius.pill,
    ...theme.mixins.focus,
  },
}));

type AvatarProps = {
  image?: string;
  placeholder?: string;
  href?: string;
  size?: string;
  alt?: string;
} & SpacingProps;

export default function Avatar({ image, href, size, alt, ...rest }: AvatarProps) {
  return (
    <AvatarWrapper
      placeholder={image ? '/static/images/avatar.svg' : undefined}
      title={alt}
      as={href ? AvatarLink : 'div'}
      href={href}
      size={size}
      {...rest}
    >
      {image ? <CardImage src={image + '?w=200'} alt={alt} /> : <Placeholder />}
    </AvatarWrapper>
  );
}

const Placeholder = () => (
  <PlaceholderWrapper>
    <IconProfileAvatar />
  </PlaceholderWrapper>
);

const PlaceholderWrapper = styled.div(() => ({
  '> svg': {
    display: 'block',
    width: '100%',
    height: '100%',
  },
}));

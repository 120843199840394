import { IconCreateRound, IconLock } from 'common/icons';
import { EllipsisText, Flex, Icon, NavListItem } from 'common/styles';
import { __t } from 'common/i18n';
import styled from '@emotion/styled';
import IconSetting from 'common/icons/IconSetting';
import { useEffect, useState } from 'react';
import { MultiProfileSelectorProps, ProfileSwitch, UsersProfileInfo } from 'common/apiService';
import { IconContainerShimmer, TitleShimmer } from 'common/styles/ShimmerAnimation';
import { useRouter } from 'next/router';
import lscache from 'lscache';
import { paperBg } from 'common/styles/Paper';
import { useProfile, useTheme, useUser } from 'common/CacheProvider';
import theme from 'common/theme';
import UserAvatar from './UserAvatar';

const ProfilePicker = () => {
  const [profileList, setProfileList] = useState<MultiProfileSelectorProps[]>([]);
  const [loading, setIsLoading] = useState<boolean>(false);
  const { profile } = useProfile();
  useEffect(() => {
    handleProfilesData();
  }, []);
  const handleProfilesData = () => {
    UsersProfileInfo().then((response) => {
      if (response.status === 200) {
        setIsLoading(true);
        setProfileList(response.data);
        setIsLoading(false);
      }
    }).catch(() => {
      setIsLoading(false);
    });
  };
  return <>
    {!loading && profileList && profileList.length > 0
      ? profileList.map((i) => <ProfileItem key={i.id} profileItem={i} />) : <ProfileSkeleton />}
    {profileList?.length < 3 && String(profile?.isPrimary) === 'true' && <ProfileCreate />}
  </>;
};

export default ProfilePicker;

const ProfileItem = ({ profileItem }) => {
  const { revalidate } = useUser();
  const { avatar, name, has_pin, id: profile_id, age_type } = profileItem;
  const router = useRouter();
  const { profile, updateProfile } = useProfile();
  const handleClickSetting = (event) => {
    event.stopPropagation();
    router.push({ pathname: '/user/edit', query: { profileId: profile_id } });
  };

  const handleSelectProfile = () => {
    if (String(profile?.profileId) !== String(profile_id)) {
      lscache.set('selected_pined_profile', { avatar: avatar?.image_path || avatar, name, profile_id, age_type });
      if (has_pin) {
        router.push({ pathname: '/profile/pin', query: { profileId: profile_id } });
      } else {
        switchToNewProfile(String(profile_id));
      }
    }
  };
  const switchToNewProfile = async (profileId: string) => {
    const body = {
      profile_id: profileId,
      pin: '',
    };
    await ProfileSwitch(body).then((response) => {
      if (response.status === 200) {
        updateProfile({
          profileId: String(response.data.profile.id),
          isPrimary: String(response.data.profile.is_primary),
          profileAccessToken: response.data.profile_access_token,
          age_type: response.data.profile.age_type,
        });
        if (response.data.profile.age_type !== 'adult') {
          router.push('/categories/scNnlM/کودک-و-نوجوان').then(() => {
            revalidate();
          });
        } else {
          if (router.asPath === '/') {
            window.location.reload();
          } else {
            router.push('/').then(() => {
              revalidate();
            });
          }
        }
      }
    });
  };

  const handleClickOnPin = (event) => {
    event.stopPropagation();
    lscache.set('selected_pined_profile', { avatar: avatar?.image_path || avatar, name, profile_id, age_type });
    router.push({ pathname: '/profile/pin', query: { profileId: profile_id } });
  };
  const { currentTheme } = useTheme();
  return <FlexWrapper fullWidth position="relative" onClick={handleSelectProfile}>
    <ProfileNavItem align='center'>
      <UserAvatar image={avatar} profileInfo={profileItem} size='small' />
      <EllipsisText maxWidth="8.5rem" line={1} mr={1}>{name || __t('multi.profile.default.name')}</EllipsisText>
    </ProfileNavItem>
    <ActionContainer>
      {has_pin && profile?.profileId !== profile_id &&
        <IconContainer size="large" onClick={(event) => handleClickOnPin(event)}>
          <IconLock currentColor={currentTheme === 'light' ? theme.palette.background[800][0] : 'white'} /></IconContainer>}
      {(profile?.profileId === profile_id) ? <IconContainer size="large"
        onClick={(event) => handleClickSetting(event)}>
        <IconSetting currentColor={currentTheme === 'light' ? theme.palette.background[800][0] : 'white'} /></IconContainer> : <></>}
    </ActionContainer>
  </FlexWrapper>;
};

const ProfileCreate = () => {
  return <NavListItem mb="1px" href={'/profile/type'}>
    <Icon size="large" ml={1}><IconCreateRound /></Icon>
    {__t('multi.profile.add.new')}
  </NavListItem>;
};

export const ActionContainer = styled.div(() => ({
  cursor: 'pointer',
  position: 'absolute',
  left: '0.5rem',
  top: '50%',
  transform: 'translateY(-50%)',
}));

export const IconContainer = styled(Icon)(() => ({
  cursor: 'pointer',
  '&:hover': {
    transform: 'scale(1.2)',
    transition: 'transform 0.5s ease, top 1s ease',
  },
}));

export const FlexWrapper = styled(Flex)(({ theme }) => ({
  cursor: 'pointer',
  borderRadius: theme.radius.default,
  '&:hover': {
    backgroundColor: theme.palette.background[paperBg.strong][0],
  },
}));

const ProfileNavItem = styled(Flex)(({ theme }) => ({
  ...theme.font.responsiveSize(2),
  padding: theme.spacing(2),
  borderRadius: theme.radius.default,
  display: 'flex',
  '&:hover': {
    backgroundColor: theme.palette.background[paperBg.strong][0],
  },
  ['svg']: {
    verticalAlign: 'baseline',
  },
}));

const ProfileSkeleton = () => {
  return (
    <Flex>
      <IconContainerShimmer round />
      <TitleShimmer />
    </Flex>
  );
};

import React from 'react';

export default function IconCreateRound() {
  return (
    <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="48" cy="48" r="48" fill="#5C5C5C" />
      <rect x="35" y="47" width="24" height="2" rx="1" fill="#D9D9D9" />
      <rect x="46" y="60" width="24" height="2" rx="1" transform="rotate(-90 46 60)" fill="#D9D9D9" />
    </svg>
  );
}

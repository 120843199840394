import styled from '@emotion/styled';
import { mainBg } from './Main';

const BackDrop = styled.div<{ passive?: boolean; }>(({ theme, passive }) => ({
  pointerEvents: passive ? 'none' : 'initial',
  display: 'flex',
  top: 0,
  left: 0,
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  minWidth: '100%',
  minHeight: '100%',
  width: '100vw',
  height: '100vh',
  backgroundColor: theme.rgba(theme.palette.background[mainBg][0], .75),
  zIndex: theme.zIndex.modal,
}));

export default BackDrop;

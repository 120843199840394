import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const pulse = keyframes`
  0% { opacity: 1 }
  50% { opacity: .25 }
  100% { opacity: 1 }
`;

type PlaceholderProps = {
  width?: number,
  height?: number,
  fullWidth?: boolean,
  block?: boolean,
};

const Placeholder = styled.span<PlaceholderProps>(({ theme, width = 5, height = 1, block, fullWidth }) => ({
  display: (block || height) ? 'block' : 'inline-block',
  borderRadius: theme.radius.medium,
  width: fullWidth ? '100%' : (width + 'em'),
  height: height + 'em',
  backgroundColor: theme.palette.background[500][0],
  animation: `${pulse} 1s linear infinite`,
}));

export default Placeholder;

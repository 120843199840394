import React from 'react';

export default function IconError() {
  return (
    <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.75508 0.727272C8.31494 -0.242425 9.71457 -0.242424 10.2744 0.727273L17.8325 13.8182C18.3923 14.7879 17.6925 16 16.5728 16H1.45671C0.337005 16 -0.362812 14.7879 0.197042 13.8182L7.75508 0.727272ZM9 4C9.55228 4 10 4.39175 10 4.875V10.125C10 10.6082 9.55228 11 9 11C8.44772 11 8 10.6082 8 10.125V4.875C8 4.39175 8.44772 4 9 4ZM9 14C9.55229 14 10 13.5523 10 13C10 12.4477 9.55229 12 9 12C8.44771 12 8 12.4477 8 13C8 13.5523 8.44771 14 9 14Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default function IconInstagram() {
  return (
    <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 11.8408L1 5.92884C1 4.62172 1.52003 3.36812 2.4457 2.44384C3.37136 1.51956 4.62683 1.00031 5.93592 1.00031H11.8567C13.1658 1.00031 14.4213 1.51956 15.3469 2.44384C16.2726 3.36812 16.7926 4.62172 16.7926 5.92884V11.8408C16.7869 12.7352 16.5402 13.6116 16.0785 14.3781C15.6167 15.1446 14.957 15.7729 14.1684 16.1972C13.4593 16.5797 12.6646 16.7766 11.8586 16.7693H5.93783"
        stroke="currentColor" strokeLinecap="round"
      />
      <path
        d="M8.89845 12.2906C10.7824 12.2906 12.3096 10.7657 12.3096 8.88457C12.3096 7.00346 10.7824 5.47852 8.89845 5.47852C7.01453 5.47852 5.4873 7.00346 5.4873 8.88457C5.4873 10.7657 7.01453 12.2906 8.89845 12.2906Z"
        stroke="currentColor"
      />
      <path
        d="M13.2627 5.61403C13.8658 5.61403 14.3546 5.12592 14.3546 4.52381C14.3546 3.9217 13.8658 3.43359 13.2627 3.43359C12.6597 3.43359 12.1709 3.9217 12.1709 4.52381C12.1709 5.12592 12.6597 5.61403 13.2627 5.61403Z"
        fill="currentColor"
      />
    </svg>

  );
}

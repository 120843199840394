import { useEffect, useState } from 'react';

const useScrollTop = () => {
  const [supportScroll, setSupportScroll] = useState(false);

  useEffect(() => {
    setSupportScroll('scrollBehavior' in document?.documentElement?.style);
  }, []);

  const scrollToTop = (targetTop = 0, scrollDuration = 1000) => {
    const startTime = performance.now();

    const animateScroll = (timestamp) => {
      const timeElapsed = timestamp - startTime;
      const progress = Math.min(timeElapsed / scrollDuration, 1);

      const currentTop = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop;
      const newTop = currentTop + (targetTop - currentTop) * progress;
      window.scrollTo(0, newTop);

      if (progress < 1) {
        requestAnimationFrame(animateScroll);
      }
    };

    requestAnimationFrame(animateScroll);
  };

  return { supportScroll, scrollToTop };
};

export default useScrollTop;

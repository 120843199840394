import React, { useEffect, useState } from 'react';
import { NotificationType } from './AlertService/AlertService';
import Popper, { PopperProps } from './Popper';
import { SpacingIndex } from './theme';

function Tooltip(
  { open, anchor, children, disableMobileHover = false, type = 'text', pInline = 1, pBlock = 1, ...rest }: PopperProps & { type?: NotificationType, pInline?: string | SpacingIndex, pBlock?: string | SpacingIndex},
  ref: React.ForwardedRef<HTMLDivElement | null>,
) {
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    if (anchor && !disableMobileHover) {
      const handleMouseEnter = () => setIsHovering(true);
      const handleMouseLeave = () => setIsHovering(false);

      anchor.addEventListener('mouseenter', handleMouseEnter);
      anchor.addEventListener('focus', handleMouseEnter);
      anchor.addEventListener('mouseleave', handleMouseLeave);
      anchor.addEventListener('blur', handleMouseLeave);
      return () => {
        anchor.removeEventListener('mouseenter', handleMouseEnter);
        anchor.removeEventListener('focus', handleMouseEnter);
        anchor.removeEventListener('mouseleave', handleMouseLeave);
        anchor.removeEventListener('blur', handleMouseLeave);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchor]);

  return (
    <Popper anchor={anchor} disableMobileHover={disableMobileHover} radius="medium" passive showArrow px={pInline} py={pBlock} type={type} open={open || isHovering} ref={ref} {...rest}>
      {children}
    </Popper>
  );
}

// eslint-disable-next-line max-len
export default React.forwardRef<HTMLDivElement | null, PopperProps & { type?: NotificationType, pInline?: string | SpacingIndex, pBlock?: string | SpacingIndex }>(Tooltip);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// const TooltipExample = () => {
//   const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
//
//   return (
//     <div>
//       <div ref={setReferenceElement}>لنگر</div>
//       <Tooltip anchor={referenceElement}>تول‌تیپ</Tooltip>
//     </div>
//   );
// };

import React, { createContext, useContext, useEffect, useState } from 'react';
import LanguageManager, { LanguageOption } from './LanguageManager';

const LanguageContext = createContext<LanguageOption>(LanguageManager.get());

export default function LanguageProvider({ children }: { children?: React.ReactNode; }) {
  const [language, setLanguage] = useState<LanguageOption>(LanguageManager.get());
  useEffect(() => {
    const handleChange = (newLang: LanguageOption) => setLanguage(newLang);
    LanguageManager.addListener(handleChange);
    setLanguage(LanguageManager.get());
    return () => {
      LanguageManager.removeListener(handleChange);
    };
  }, []);
  return <LanguageContext.Provider key={language.key} value={language} >
    {children}
  </LanguageContext.Provider>;
}

export const useLanguage = () => useContext(LanguageContext);

import styled from '@emotion/styled';
import QueryString from 'qs';
import { GlobalDataType } from 'common/apiService';
import { Icon, Link } from 'common/styles';
import { __t } from 'common/i18n';
// import { IconCinemaTicket } from 'common/icons';
import { useRouter } from 'next/router';
import { useUser } from '../CacheProvider';
// import IconLiveStreaming from 'common/icons/IconLiveStreaming';

const LinksList = styled.ul(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: theme.spacing(1, 0),
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    padding: 0,
    width: 'auto',
    height: '100%',
    flexDirection: 'row',
  },
}));

const ListItem = styled.li<{ active?: boolean }>(({ theme, active }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 0),
  marginRight: theme.spacing(2),
  borderBottom: active ? '1px solid hsl(349, 66%, 40%)' : '1px solid transparent',
  '&:hover': {
    borderBottom: '1px solid hsl(349, 66%, 40%)',
  },
  [theme.breakpoints.down('md')]: {
    borderBottom: '1px solid transparent',
    '&:hover': {
      borderBottom: '1px solid transparent',
    },
  },
}));

function getUrl(config) {
  if (config?.navigation_type === 'internal_route') return config?.url?.replace?.('https://filmnet.ir', '');
  if (config?.navigation_type === 'get_by_filter') {
    const x: any = {};
    if (config?.category_id) x.categories = config?.category_id;
    if (config?.sort_order) x.order = config?.sort_order;
    return `/contents?${QueryString.stringify(x)}`;
  }
}

export default function MenuLinks({ className = '', data = {} }: { className?: string; data?: GlobalDataType }) {
  const { user } = useUser();
  const router = useRouter();
  return (
    <LinksList className={className}>
      <>
        <ListItem className="mobile-only">
          <Link href="/contents?types=single_video">{__t('content.type.movie')}</Link>
        </ListItem>
        <ListItem className="mobile-only">
          <Link href="/contents?types=series">{__t('content.type.tv_show')}</Link>
        </ListItem>
      </>
      <ListItem active={router.asPath === '/categories'}>
        <Link href="/categories">{__t('common.category')}</Link>
      </ListItem>
      <ListItem active={router.asPath.includes('scNnlM')}>
        <Link href="/categories/scNnlM/کودک-و-نوجوان">{__t('common.kids')}</Link>
      </ListItem>
      {data.links && data.links?.[0]?.items.map(({ id, configuration, display_title }) => {
        return <ListItem key={id} active={router.asPath.includes(getUrl(configuration))}>
          <Link href={getUrl(configuration) || '' }>{display_title}</Link>
        </ListItem>;
      })}
      {!user?.is_sso_login && <ListItem active={router.pathname.includes('/cinema-online')}>
        <CinemaLink/>
      </ListItem>}
      {/* <ListItem active={router.asPath.includes('live')}>
        <LiveLink />
      </ListItem> */}
    </LinksList>
  );
}

const CinemaLink = () => (
  <CinemaLinkStyle href="/cinema-online">
    {/* <Icon size="large"> */}
    {/*   <IconCinemaTicket /> */}
    {/* </Icon> */}
    <span>{__t('cinema-online.title')}</span>
  </CinemaLinkStyle>
);

// const LiveLink = () => (
//   <Link href="/live-stream">
//     <Icon size="small">
//       <IconLiveStreaming />
//     </Icon>
//     <span style={{ paddingRight: '4px' }}>{__t('common.live')}</span>
//   </Link>
// );

const CinemaLinkStyle = styled(Link)<{ active?: boolean }>(({ theme }) => ({
  // fontWeight: 'bold',
  [`${Icon}`]: {
    color: theme.palette.warning[900][0],
    transform: 'scale(1.7) rotate(-35deg) translateY(-.1em)',
    marginLeft: '-.35em',
    position: 'relative',
    zIndex: -1,
  },
}));

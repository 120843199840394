import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import lscache from 'lscache';
import { User, getCurrentUser } from '../apiService';
import { Action, Dispatch, GlobalState, GlobalProfile, Theme } from './types';
import { actionLoad, actionUpdateAgent, actionUpdateUser, actionChangeTheme, actionUpdateProfile } from './actions';
import { useRouter } from 'next/router';

const initialState: GlobalState = {
  user: null,
  access_token: null,
  profile: null,
  isReady: false,
  currentTheme: 'dark',
  isTvAgent: false,
};

const reducer = (state: GlobalState, action: Action): GlobalState => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, ...action.payload };
    case 'LOGOUT':
      return { ...state, user: null, access_token: null, profile: null };
    case 'LOAD':
      return { ...state, ...action.payload, isReady: true };
    case 'UPDATE_USER':
      return { ...state, user: action.payload };
    case 'UPDATE_THEME':
      return { ...state, currentTheme: action.payload };
    case 'UPDATE_PROFILE':
      return { ...state, profile: action.payload };
    case 'UPDATE_AGENT':
      return { ...state, isTvAgent: action.payload };
    default:
      return state;
  }
};

type GlobalContext = { state: GlobalState, dispatch: Dispatch };
const store = createContext<GlobalContext>({ state: initialState, dispatch: () => 0 });
const { Provider } = store;
export const CacheProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const router = useRouter();
  const lightPages = ['/packages', '/dd-agreement', '/payment', '/tickets', '/categories/scNnlM'];

  useEffect(() => {
    const cachedState = lscache.get('state') as GlobalState;
    if (cachedState) {
      dispatch(actionLoad(cachedState));
    } else {
      dispatch(actionLoad(initialState));
    }
  }, []);

  useEffect(() => {
    if (state !== initialState) {
      lscache.set('state', state);
    }
  }, [state]);

  // // revalidate new user when access_token changes to update user subscription remaining in days
  // useEffect(() => {
  //   if (state.access_token && state.access_token !== prevToken.current) {
  //     prevToken.current = state.access_token;
  //     getCurrentUser().then(({ data }) => {
  //       dispatch(actionUpdateUser(data));
  //     });
  //   }
  // }, [state.access_token]);

  useEffect(() => {
    if (state && state.access_token) {
      if (!router.asPath.includes('/profile') && !router.asPath.includes('/login') && !router.asPath.includes('/register') && !router.asPath.includes('/tv') && !router.asPath.includes('/sso') && !state.profile) {
        router.push(!router.asPath.includes('/profile') && !router.asPath.includes('/login') && !router.asPath.includes('/register') && !router.asPath.includes('/tv') && !router.asPath.includes('/sso') ? `/profile?redirect=${router.asPath}` : '/profile');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath, state]);

  useEffect(() => {
    if (router.pathname) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < lightPages.length; i++) {
        if (router.pathname.includes(lightPages[i]) || router.asPath.includes(lightPages[i])) {
          return dispatch(actionChangeTheme('light'));
        }
      }
      return dispatch(actionChangeTheme('dark'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  const contextValue = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <Provider value={contextValue}>
      {children}
    </Provider>
  );
};

export const useDispatch = () => {
  const { dispatch } = useContext(store);
  return dispatch;
};

export const useUser = () => {
  const { state, dispatch } = useContext(store);
  const { user, isReady, access_token } = state;
  const authorized = !!access_token;
  // const hasSubscription = user?.conditional_flag?.includes('has_subscription') ?? false;
  const hasSubscription: boolean = (user?.conditional_flag?.includes('has_subscription') || user?.conditional_flag?.includes('has_carrier_subscription')) ?? false;
  const subscriptionRemainingDays = user?.subscription_remaining_in_day || 0;

  const staticUserRevalidate = useCallback((user: User) => {
    dispatch(actionUpdateUser(user));
  }, [dispatch]);

  const revalidate = useCallback(() => getCurrentUser().then(({ data: user }) => {
    dispatch(actionUpdateUser(user));
  }), [dispatch]);

  return { user, authorized, hasSubscription, loaded: isReady, subscriptionRemainingDays, revalidate, staticUserRevalidate };
};

// export const useLogout = (callback?) => {
//   const { dispatch } = useContext(store);
//   const f = () => {
//     dispatch(actionLogout());
//     if (callback) callback();
//   };
//   const logoutAction = () => {
//     logout().then(f, f);
//   };
//   return { logoutAction };
// };

export const useTheme = () => {
  const { state, dispatch } = useContext(store);
  const { currentTheme } = state;
  const updateTheme = (theme: Theme) => {
    dispatch(actionChangeTheme(theme ? theme : 'dark'));
  };
  return { currentTheme, updateTheme };
};

export const useProfile = () => {
  const { state, dispatch } = useContext(store);
  const { profile } = state;
  const updateProfile = (profile: GlobalProfile) => {
    dispatch(actionUpdateProfile(profile));
  };
  return { profile, updateProfile };
};

export const useAgent = () => {
  const { state, dispatch } = useContext(store);
  const { isTvAgent } = state;
  const updateAgent = (agent: boolean) => {
    dispatch(actionUpdateAgent(agent));
  };
  return { isTvAgent, updateAgent };
};

import React from 'react';

export default function IconDotsVertical() {
  return (
    <svg width="4" height="16" viewBox="0 0 4 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <circle cx="2" cy="14" r="2" transform="rotate(90 2 14)" fill="currentColor" />
      <circle cx="2" cy="8" r="2" transform="rotate(90 2 8)" fill="currentColor" />
      <circle cx="2" cy="2" r="2" transform="rotate(90 2 2)" fill="currentColor" />
    </svg>
  );
}

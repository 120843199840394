import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { ColorKey } from 'common/theme';

const indeterminateFirst = keyframes`
  0% {
      left: 100%;
      width: 100%;
  }
  100% {
      left: -100%;
      width: 10%;
  }
`;

const indeterminateSecond = keyframes`
  0% {
      left: 100%;
      width: 100%;
  }
  100% {
      left: -125%;
      width: 10%;
  }
`;

const Wrapper = styled.div<{ color?: ColorKey }>(({ theme, color = 'foreground' }) => {
  const _color = theme.palette[color][900][0];
  return ({
    overflow: 'hidden',
    width: '100%',
    height: '3px',
    backgroundColor: theme.rgba(_color, .25),
    margin: '0 auto',
    [`${Indeterminate}`]: {
      '&::before': {
        backgroundColor: theme.rgba(_color, .75),
        animation: `${indeterminateFirst} 1.5s infinite ease-out`,
      },
      '&::after': {
        backgroundColor: theme.rgba(_color, .5),
        animation: `${indeterminateSecond} 1.5s infinite ease-in`,
      },
    },
  });
});

const Indeterminate = styled.div(() => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    height: '100%',
  },
}));

type LinearProgressProps = {
  color?: ColorKey;
};

export default function LinearProgress({ color }: LinearProgressProps) {
  return (
    <Wrapper color={color}>
      <Indeterminate />
    </Wrapper>
  );
}

import styled from '@emotion/styled';
import { Flex, Icon, Link, Text } from 'common/styles';
import { ComponentProps, FC, ReactNode } from 'react';
import { IconAngleLeft } from './icons';
import Scroller from './Scroller';
import { globalPadding } from './Layout/Layout';
import withStyleSystem, { SpacingProps } from './withStyleSystem';
import IconScheduleTable from './icons/IconScheduleTable';

const Container = withStyleSystem(styled.section(({ theme }) => ({
  position: 'relative',
  margin: theme.spacing(7, 0),
})));

interface TitleElementsProps {
  title: CarouselProps['title'];
  url?: string;
  customUrl?: string
}

const MoreLink = styled(Link)(({ theme }) => ({
  [`${Icon}`]: {
    margin: '0 -.1em',
    fontSize: '1.2em',
    transition: 'color .3s ease',
  },
  [`&:hover ${Icon}`]: {
    color: theme.palette.primary[800][0],
  },
}));

const TitleElements: FC<TitleElementsProps> = ({ title, url, customUrl }) => {
  if (!url) return <>{title}</>;

  let href = title !== 'سینما آنلاین' ? url.toLowerCase().replace('offset=0&count=24&', '') : '/cinema-online';
  if (href.startsWith('/video-contents')) href = href.replace('/video-contents', '/contents');

  return (
    <MoreLink href={customUrl ? customUrl : href}>
      {title}<Icon cursor="pointer"><IconAngleLeft /></Icon>
    </MoreLink>
  );
};

type CarouselProps = {
  title?: string | ReactNode;
  more?: string;
  customUrl?: string
} & Pick<ComponentProps<typeof Scroller>, 'arrows' | 'background'> & SpacingProps;

const Carousel: FC<CarouselProps> = ({
  title,
  children,
  background,
  more,
  arrows = true,
  customUrl,
  ...styleSystem
}) => (
  <Container className="carousel" {...styleSystem}>
    <Flex>
      {title && (
        <Text variant="h2" weight="medium" mb={1}>
          <TitleElements title={title} url={more} customUrl={customUrl} />
        </Text>
      )}
      {customUrl && (
        <Text variant="h2" weight="medium" mb={1} mr="auto">
          <MoreLink href={customUrl as string}>
            <Icon cursor="pointer" ml={'1rem'}><IconScheduleTable /></Icon>{title}
          </MoreLink>
        </Text>
      )}
    </Flex>
    <Scroller arrows={arrows} cancelPadding={globalPadding} background={background}>
      {children}
    </Scroller>
  </Container>
);

export default Carousel;

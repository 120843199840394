import styled from '@emotion/styled';
import Header from 'common/Header';
import React from 'react';
import Footer from '../Footer/Footer';
import { headerHeight } from '../Header';
import { mainBg } from '../styles/Main';
import { BreakpointKeys, SpacingIndex } from '../theme';
import MiniFooter from '../Footer/MiniFooter';
import StaticMiniFooter from '../Footer/StaticMiniFooter';

type MainProps = {
  disableTopGutter?: boolean,
};

export const globalPadding: Partial<Record<BreakpointKeys, SpacingIndex>> = {
  xs: 2,
  sm: 4,
  md: 7,
  xl: 9,
};

export const Container = styled.div<MainProps>(({ theme, disableTopGutter }) => ({
  ...theme.mixins.create(globalPadding, (p) => ({
    paddingRight: theme.spacing(p),
    paddingLeft: theme.spacing(p),
  })),
  backgroundColor: theme.palette.background[mainBg][0],
  color: theme.palette.background[mainBg][1],
  paddingBottom: theme.spacing(8),
  paddingTop: !disableTopGutter ? `calc(${headerHeight} + ${theme.spacing(3)})` : theme.spacing(3),
  flexGrow: 1,
}));

export type LayoutProps = {
  children?: React.ReactNode,
  disableFooter?: boolean,
  disableMiniFooter?: boolean,
  showStaticFooter?: boolean
} & React.ComponentPropsWithoutRef<typeof Container>;

export default function Layout({ children, disableFooter, disableMiniFooter, showStaticFooter, ...rest }: LayoutProps) {
  return (
    <>
      <Header />
      <Container {...rest} id="main-container">
        {children}
      </Container>
      {!disableMiniFooter && <MiniFooter footerDisabled={disableFooter} />}
      {!disableFooter && <Footer />}
      {disableFooter && disableMiniFooter && showStaticFooter && <StaticMiniFooter />}
    </>
  );
}

import styled from '@emotion/styled';
import withStyleSystem from 'common/withStyleSystem';
import { Text, Paper } from 'common/styles';
import { __t } from 'common/i18n';
import { useUser } from 'common/CacheProvider';
import { paperBg } from './styles/Paper';

const Wrapper = styled(Paper)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background[paperBg.strong][0],
  padding: theme.spacing(1, 2),
}));

const CarrierWrapper = styled.div<{ isLarge?: boolean }>(({ theme, isLarge }) => ({
  width: '100%',
  marginTop: isLarge ? '1rem' : '0.5rem',
  fontSize: isLarge ? '14px' : '12px',
  color: isLarge ? theme.palette.text[800][0] : theme.palette.foreground[500][0],
  textAlign: isLarge ? 'center' : 'right',
}));

const SubscriptionStatus = ({ isLarge }: { isLarge?: boolean }) => {
  const { hasSubscription, subscriptionRemainingDays, user } = useUser();

  const handleSubscriptionStatus = () => {
    if (!hasSubscription) {
      return __t('profile.subscription.no_subscription');
    }
    if (subscriptionRemainingDays > 0) {
      if (user?.conditional_flag !== 'has_carrier_subscription') {
        return __t('profile.subscription.remaining', { day: subscriptionRemainingDays });
      } else if (user?.carrier_subscription_title_text) {
        return user?.carrier_subscription_title_text;
      }
    } else if (subscriptionRemainingDays <= 0 && user?.carrier_subscription_title_text) {
      return user?.carrier_subscription_title_text;
    }
    return __t('profile.subscription.remaining', { day: subscriptionRemainingDays });
  };

  return (
    <>
      <Wrapper>
        <Text variant="body2" color={!hasSubscription ? 'weak' : 'weakSuccess'} align="center">
          {handleSubscriptionStatus()}
        </Text>
      </Wrapper>
      {hasSubscription && subscriptionRemainingDays <= 0 && user?.carrier_subscription_menu_description_text &&
        <CarrierWrapper isLarge={isLarge}>{user?.carrier_subscription_menu_description_text}</CarrierWrapper>
      }
    </>
  );
};

export default withStyleSystem(SubscriptionStatus);

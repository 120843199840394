import styled from '@emotion/styled';
import { useUser } from 'common/CacheProvider';
import { globalPadding } from 'common/Layout/Layout';
import { IconClose } from 'common/icons';
import EllipsisText from 'common/styles/EllipsisText';
import withStyleSystem from '../withStyleSystem';
import { ColorKey } from '../theme';
import { popUpMessaging } from 'common/apiService';
import { useEffect, useState } from 'react';
import lscache from 'lscache';
import { useRouter } from 'next/router';

const MessageWrapper = styled.div<{ type?: string }>(({ theme, type }) => ({
  background: type && type === 'error' ? theme.palette.warning[100][0] : theme.palette.success[300][0],
  padding: theme.spacing(2, 0),
  color: theme.palette.text[900][0],
  ...theme.mixins.create(globalPadding, (p) => ({ padding: `12px ${theme.spacing(p)}` })),
  height: '48px',
  position: 'fixed',
  width: '100%',
  top: 0,
  fontSize: '0.875rem',
  zIndex: theme.zIndex.systemMessage,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  [theme.breakpoints.down('md')]: {
    height: '72px',
  },
}));

const IconWrapper = withStyleSystem(styled.div<{ color?: ColorKey }>(({ theme, color }) => ({
  cursor: 'pointer',
  color: color ? theme.palette[color][900][0] : undefined,
  verticalAlign: 'middle',
  width: '20px',
  height: '25px',
  marginLeft: '0.5rem',
  [theme.breakpoints.down('xs')]: {
    height: '28px',
    width: '35px',
  },
})));

export interface SystemMessage {
  message: string
  isClosedBefore: boolean
  is_dismissable: boolean
  type: string
}

const SystemMessage = () => {
  const [system_message, setSystemMessage] = useState<SystemMessage | null>(null);
  const { loaded } = useUser();
  const router = useRouter();

  const handleGetPopUpMessage = async () => {
    await popUpMessaging().then(({ data }) => {
      if (data) {
        if (!system_message || data?.text !== system_message?.message || !system_message?.isClosedBefore) {
          lscache.set('system_message', {
            message: data?.text,
            isClosedBefore: false,
            is_dismissable: data.is_dismissable,
            type: data?.type,
          });
        }
      } else {
        if (system_message) {
          lscache.set('system_message', null);
        }
      }
    }).catch(() => {
      lscache.set('system_message', null);
    });
  };

  useEffect(() => {
    if (window) {
      setSystemMessage(lscache.get('system_message'));
      window.addEventListener('storage', () => {
        setSystemMessage(lscache.get('system_message'));
      });
    }

    return () => {
      if (window) {
        window.removeEventListener('storage', () => {
          setSystemMessage(null);
        });
      }
    };
  }, []);

  useEffect(() => {
    loaded && handleGetPopUpMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  const handleMaxLine = () => {
    if (window) {
      if (window.innerWidth < 960) {
        return 2;
      } else if (window.innerWidth >= 960) {
        return 1;
      }
      return 1;
    }
  };
  if (!system_message?.message || system_message?.isClosedBefore || router.asPath.includes('play')) return <></>;
  return <MessageWrapper type={system_message?.type}>
    {system_message.is_dismissable && <IconWrapper onClick={() => {
      lscache.set('system_message', { ...system_message, isClosedBefore: true });
      window.dispatchEvent(new Event('storage'));
    }}>
      <IconClose /></IconWrapper>}
    <EllipsisText color='black' line={handleMaxLine()} >{system_message?.message}</EllipsisText>
  </MessageWrapper>;
};

export default SystemMessage;

import styled from '@emotion/styled';
import { InputSize } from 'common/theme';
import withStyleSystem from 'common/withStyleSystem';
import Icon from './Icon';
import { inputSizeStyles } from './Input';
import RotatableIcon from './RotatableIcon';
import { SpinnerIcon } from './Spinner';

export type ButtonVariant = 'primaryA' | 'primaryB' | 'secondary' | 'flat' | 'success';

type ButtonProps = {
  variant?: ButtonVariant;
  fullWidth?: boolean,
  disabled?: boolean,
  iconStart?: boolean,
  iconEnd?: boolean,
  href?: string,
  wrap?: boolean,
  block?: boolean,
  isActive?: boolean,
  inputSize?: InputSize
};

const Button = styled.button<ButtonProps>(({
  theme, variant = 'flat', fullWidth, block, disabled, iconStart, iconEnd, wrap, inputSize = 'default',
}) => {
  const disabledColor = theme.palette.background[100][0];
  const disabledContrast = theme.palette.background[300][0];

  const variants = {
    primaryA: () => {
      const [bg, fg] = theme.palette.primary[900];
      const [bgActive] = theme.palette.primary[800];

      return {
        backgroundColor: bg,
        color: fg,
        '&:hover, &:active, &:focus': {
          backgroundColor: bgActive,
        },
        '&:disabled': {
          backgroundColor: disabledColor,
          color: disabledContrast,
        },

      };
    },
    primaryB: () => {
      const [bg, fg] = theme.palette.foreground[700];
      const [bgActive] = theme.palette.foreground[500];

      return {
        backgroundColor: bg,
        color: fg,
        '&:hover, &:active, &:focus': {
          backgroundColor: bgActive,
        },
        '&:disabled': {
          backgroundColor: disabledColor,
          color: disabledContrast,
        },

      };
    },
    secondary: () => {
      const [fg] = theme.palette.foreground[900];
      const [bg] = theme.palette.background[100];
      const bgDefault = theme.rgba(bg, .5);
      const bgActive = theme.rgba(bg, .4);
      const altDisabledBgColor = theme.rgba(fg, .5);
      const [altDisabledColor] = theme.palette.foreground[100];

      return {
        backgroundColor: bgDefault,
        color: fg,
        '&:hover, &:active, &:focus': {
          backgroundColor: bgActive,
        },
        '&:disabled': {
          backgroundColor: altDisabledBgColor,
          color: altDisabledColor,
        },
      };
    },
    flat: () => {
      const fg = theme.palette.foreground[900][0];
      const bgActive = theme.palette.background[600][0];
      const altDisabledColor = theme.rgba(fg, .34);
      return {
        backgroundColor: 'transparent',
        color: fg,
        '&:hover, &:active, &:focus': {
          backgroundColor: bgActive,
        },
        '&:disabled': {
          color: altDisabledColor,
          backgroundColor: 'transparent',
        },
      };
    },
    success: () => {
      const [bg, fg] = theme.palette.success[900];
      const [bgActive] = theme.palette.success[800];

      return {
        backgroundColor: bg,
        color: fg,
        '&:hover, &:active, &:focus': {
          backgroundColor: bgActive,
        },
        '&:disabled': {
          backgroundColor: disabledColor,
          color: disabledContrast,
        },

      };
    },
  };

  const { padding, fontSize } = inputSizeStyles[inputSize];

  return ({
    fontWeight: theme.font.weight.medium,
    whiteSpace: wrap ? 'normal' : 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: (fullWidth || block) ? 'block' : 'inline-block',
    width: fullWidth ? '100%' : undefined,
    borderRadius: inputSize === 'default' ? theme.radius.medium : theme.radius.default,
    textAlign: 'center',
    cursor: disabled ? 'default' : 'pointer',
    padding,
    transition: 'background-color .3s ease, color .3s ease',
    ...theme.font.responsiveSize(fontSize),
    ...variants[variant]?.(),
    [`${RotatableIcon}, ${Icon}, ${SpinnerIcon}`]: {
      margin: (iconStart || iconEnd) ? theme.spacing(0, 1) : undefined,
      width: '1em',
      height: '1em',
    },
    [theme.logicalProp('padding', 'start')]: iconStart ? theme.spacing(1) : undefined,
    [theme.logicalProp('padding', 'end')]: iconEnd ? theme.spacing(1) : undefined,
  });
});

export default withStyleSystem(Button);

/* eslint-disable no-undef */
import styled from '@emotion/styled';
import Collapse from 'common/Collapse';
import { IconCaution, IconClose, IconConfirm } from 'common/icons';
import { Icon, IconButton } from 'common/styles';
import { paperBg } from 'common/styles/Paper';
import { ColorKey } from 'common/theme';
import { useEffect, useRef, useState } from 'react';
import { TransitionStatus } from 'react-transition-group';
import { Notification, NotificationType } from './AlertService';

type AlertServiceNotificationProps = {
  onRemove: (id: number) => void,
  notification: Notification,
  status?: TransitionStatus,
  bottomGutter?: boolean,
};

const StyledCollapse = styled(Collapse)<{ bottomGutter?: boolean; }>(({ bottomGutter }) => ({
  marginBottom: bottomGutter ? 1 : undefined,
  boxShadow: '0 .5rem .5rem rgba(0,0,0,.1)',
}));

export const notificationColors: Record<NotificationType, [ColorKey, number]> = {
  error: ['warning', 100],
  success: ['success', 100],
  denseSuccess: ['success', 900],
  default: ['background', paperBg.main],
  text: ['background', 400],
};

const Container = styled.div<{ type: NotificationType; }>(({ theme, type }) => {
  const [color, intensity] = notificationColors[type];
  const [bg, fg] = theme.palette[color][intensity];

  return ({
    padding: theme.spacing(2, 6),
    zIndex: theme.zIndex.snackbar,
    backgroundColor: bg,
    color: fg,
    textAlign: 'center',
    position: 'relative',
    width: '100%',
  });
});

const CloseButtonWrapper = styled.div(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  top: '50%',
  transform: 'translate(0,-50%)',
  [`${IconButton}`]: {
    color: 'inherit',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export default function AlertServiceNotification(props: AlertServiceNotificationProps) {
  const { status, onRemove, notification, bottomGutter } = props;
  const onRemoveRef = useRef(onRemove);
  const [open, setOpen] = useState(status === undefined);
  onRemoveRef.current = onRemove;
  const [{ id, type, message, persistant, duration }] = useState(notification);

  useEffect(() => {
    let timeoutID: NodeJS.Timeout;
    if (!persistant) {
      timeoutID = setTimeout(() => {
        onRemoveRef.current(id);
      }, duration);
      return () => {
        clearTimeout(timeoutID);
        onRemoveRef.current(id);
      };
    }
  }, [id, persistant, duration]);

  useEffect(() => {
    if (status === 'entering')
      setOpen(true);
    if (status === 'exiting')
      setOpen(false);
  }, [status]);

  return (
    <StyledCollapse bottomGutter={bottomGutter} open={open} timeout={300}>
      {message !== '_HIDE_' && <Container type={type}>
        {persistant && (
          <CloseButtonWrapper><IconButton onClick={() => onRemove(id)}><Icon
            cursor="pointer"><IconClose /></Icon></IconButton></CloseButtonWrapper>
        )}
        {type === 'success' && <Icon ml={1} size="large"><IconConfirm /></Icon>}
        {type === 'error' && <Icon ml={1} size="large"><IconCaution /></Icon>}
        {message}
      </Container>}
    </StyledCollapse>
  );
}

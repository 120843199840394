import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import Flex from './Flex';

export const pulse = keyframes`
  0% { opacity: 1 }
  50% { opacity: .25 }
  100% { opacity: 1 }
`;

export const sizes = {
  small: 0.625,
  semiSmall: 0.8,
  inherit: 1,
  large: 1.5,
  medium: 3,
  semiMedium: 4,
  huge: 6,
  xLarge: 6.8,
};

export const IconContainerShimmer = styled(Flex)<{ round?: boolean, size?: string }>(({ round, theme, size = 'large' }) => ({
  width: round ? `${sizes[size]}em` : '60px',
  margin: 'auto 20px',
  height: round ? `${sizes[size]}em` : '40px',
  position: 'relative',
  overflow: 'hidden',
  borderRadius: round ? '50%' : '1rem',
  backgroundColor: theme.palette.background[500][0],
  animation: `${pulse} 1s linear infinite`,
}));

export const TitleShimmer = styled(Flex)(({ theme }) => ({
  width: '100px',
  height: '20px',
  backgroundColor: theme.palette.background[500][0],
  position: 'relative',
  overflow: 'hidden',
  animation: `${pulse} 1s linear infinite`,
}));

import colors from './colors';

const { black, white } = colors;

export const palette = {
  primary: {
    900: ['hsl(348, 66%, 50%)', white],
    800: ['hsl(349, 66%, 40%)', white],
  },
  success: {
    900: ['hsl(180, 100%, 29%)', white, 'hsl(180, 100%, 29%)'],
    800: ['hsl(180, 100%, 24%)', white],
    700: ['hsl(180, 100%, 19%)', white],
    400: ['hsl(173, 100%, 44%)', black],
    300: ['hsl(158, 100%, 93%)', black],
    200: ['hsl(180, 38%, 88%)', black],
    100: ['hsl(180, 39%, 93%)', 'hsl(180, 100%, 29%)'],
  },
  info: {
    900: ['hsl(158, 100%, 33%)', black],
    400: ['hsl(160, 23%, 74%)', black],
    100: ['hsl(140, 5%, 87%)', black],
  },
  warning: {
    900: ['hsl(349, 90%, 62%)', 'hsl(14, 100%, 73%)'],
    800: ['hsl(355, 73%, 56%)', 'hsl(349, 90%, 62%)'],
    700: ['hsla(340, 100%, 46%)'],
    500: ['hsl(8, 96%, 56%)', 'hsl(349, 90%, 62%)'],
    400: ['hsl(23, 97%, 46%)', 'hsl(349, 90%, 62%)'],
    300: ['hsl(14, 100%, 73%)', 'hsl(0, 0%, 11%)'],
    200: ['hsl(23, 97%, 46%)', 'hsl(0, 0%, 100%)'],
    100: ['hsl(349, 68%, 95%)', 'hsl(348, 66%, 50%)'],
  },
  foreground: {
    900: ['hsl(0, 0%, 100%)', black],
    800: ['hsl(0, 0%, 95%)', black],
    700: ['hsl(0, 0%, 93%)', 'hsl(0,0%,11%)'],
    600: ['hsl(0, 0%, 85%)', black],
    500: ['hsl(0, 0%, 81%)', black],
    400: ['hsl(0, 0%, 71%)', black],
    100: ['hsl(195, 2%, 55%)', white],
  },
  background: {
    900: ['hsl(0, 0%, 0%)', white],
    800: ['hsl(0, 0%, 11%)', white],
    700: ['hsl(0, 0%, 16%)', 'hsl(0, 0%, 100%)'],
    600: ['hsl(0, 0%, 21%)', white],
    500: ['hsl(0, 0%, 26%)', white],
    400: ['hsl(0, 0%, 31%)', white],
    300: ['hsl(0, 0%, 36%)', white],
    100: ['hsl(0, 0%, 56%)', black],
  },
  accent: {
    900: ['hsl(347, 66%, 50%)', white],
    800: ['hsl(347, 66%, 50%)', white],
    700: ['hsl(357, 100%, 61%)', white],
    400: ['hsl(60, 100%, 50%)', white],
  },
  sweet: {
    900: ['hsl(207, 90%, 48%)', white],
  },
  text: {
    900: ['hsl(0, 0%, 0%)', white],
    800: ['hsl(0, 0% 100%)', black],
  },
};

export default palette;

import React from 'react';

export default function IconDevices() {
  return (
    <svg width="24" height="17" viewBox="0 0 24 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.26463 0.695683C3.9659 0.695683 3.72926 0.944304 3.72926 1.24813V4.84927H3.0625V1.24813C3.0625 0.555634 3.60194 0 4.26463 0H22.7979C23.4606 0 24 0.555634 24 1.24813V12.0516C24 12.744 23.4606 13.2997 22.7979 13.2997H14.332V12.604H22.7979C23.0966 12.604 23.3333 12.3554 23.3333 12.0516V1.24813C23.3333 0.944305 23.0966 0.695683 22.7979 0.695683H4.26463Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16.7451 5.79725C16.4464 5.79725 16.2097 6.04588 16.2097 6.34972V13.5523C16.2097 13.8561 16.4464 14.1047 16.7451 14.1047H19.6409C19.9397 14.1047 20.1763 13.8561 20.1763 13.5523V6.34972C20.1763 6.04588 19.9397 5.79725 19.6409 5.79725H16.7451ZM15.543 6.34972C15.543 5.65722 16.0824 5.10156 16.7451 5.10156H19.6409C20.3036 5.10156 20.8431 5.65722 20.8431 6.34972V13.5523C20.8431 14.2448 20.3036 14.8004 19.6409 14.8004H16.7451C16.0824 14.8004 15.543 14.2448 15.543 13.5523V6.34972Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M1.20213 5.19568C0.903403 5.19568 0.66676 5.4443 0.66676 5.74813V13.5506C0.66676 13.8545 0.903403 14.1031 1.20213 14.1031H13.3646C13.6633 14.1031 13.8999 13.8545 13.8999 13.5506V5.74813C13.8999 5.4443 13.6633 5.19568 13.3646 5.19568H1.20213ZM0 5.74813C0 5.05564 0.539437 4.5 1.20213 4.5H13.3646C14.0273 4.5 14.5667 5.05564 14.5667 5.74813V13.5506C14.5667 14.2431 14.0273 14.7988 13.3646 14.7988H1.20213C0.539437 14.7988 0 14.2431 0 13.5506V5.74813Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 15.3536C0 14.6611 0.539438 14.1055 1.20213 14.1055H13.3646C14.0273 14.1055 14.5667 14.6611 14.5667 15.3536V16.0016H0V15.3536ZM0.668722 15.3059H13.898C13.8747 15.0242 13.6475 14.8012 13.3646 14.8012H1.20213C0.919211 14.8012 0.69198 15.0242 0.668722 15.3059Z" fill="currentColor" />
    </svg>
  );
}

import Bowser from 'bowser/bundled'; // https://lancedikson.github.io/bowser/docs/#overview
import lscache from 'lscache';

export function getUniqId() {
  const uniqueId = lscache.get('unique_id_v3');

  if (uniqueId) {
    return uniqueId;
  }
  const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  const newId = (`${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`);
  lscache.set('unique_id_v3', newId);
  // localStorage.setItem('unique_id_v3', newId);
  return newId;
}

export default function getDeviceInfo() {
  const browser = Bowser.getParser(window.navigator.userAgent);
  return ({
    unique_identifier: getUniqId(),
    platform: 'web',
    brand: browser?.getBrowser()?.name,
    model: browser?.getOS()?.name + browser?.getOS()?.version,
    app_version: browser?.getBrowser()?.name + browser?.getBrowser()?.version,
    screen_width: window.innerWidth.toString(),
    screen_height: window.innerHeight.toString(),
    notification_token: '',
    store: 'none',
  });
}

import styled from '@emotion/styled';
import React, { ComponentProps } from 'react';
import { ContentConditionalFlag, ContentFlag } from './apiService';
import { __t } from './i18n';
import { IconAudio, IconBrand, IconCinemaBadge } from './icons';
import { Icon, Tag } from './styles';

const CardBadge = ({ flag, customPosition, customColor }: { flag: string, customPosition?: Position | undefined, customColor?: ComponentProps<typeof Tag>['color'] }) => {
  const arr = flag.split(', ').filter((f) => !!flagProps[f]) as ContentFlag[];
  if (!arr.length) return null;
  const top = filterByPosition(arr, 'top').slice(-1);
  const bottom = filterByPosition(arr, 'bottom').slice(0, 1);
  const all = [...top, ...bottom];
  return (
    <>
      {all.map((f, index) => {
        const { position, color, icon } = flagProps[f] || {};
        return <Container key={f} position={customPosition ? customPosition : position} index={index} hasCustomPosition={!!customPosition} >
          <CornerTag compact size="small" px="0.5rem" position={customPosition ? customPosition : position} color={customColor ? customColor : color}>
            {icon && <Icon ml=".5em">{icon}</Icon>}
            {__t('flag.' + f)}
          </CornerTag>
        </Container>;
      })}
    </>
  );
};

// ----- //
// UTILS //
// ----- //

const flagProps: Partial<Record<Flag, FlagProps>> = {
  coming_soon: {
    position: 'bottom',
    color: 'warning',
  },
  traffic_free: {
    position: 'bottom',
    color: 'success',
  },
  free_content: {
    position: 'bottom',
    color: 'sweet',
  },
  // latest: {
  //   position: 'bottom',
  //   color: 'info',
  // },
  in_production: {
    position: 'top',
    color: 'accent3',
    icon: <IconBrand />,
  },
  exclusive_dub: {
    position: 'top',
    color: 'primary',
    icon: <IconAudio />,
  },
  cinema_online: {
    position: 'top',
    color: 'primary',
    icon: <IconCinemaBadge />,
  },
  audio_book: {
    position: 'top',
    color: 'success2',
  },
  persian_dub: {
    position: 'top',
    color: 'primary',
    icon: <IconAudio />,
  },
};

const filterByPosition = (flags: ContentFlag[], p: Position) => {
  return flags.filter((f) => flagProps[f]?.position === p);
};

// ------ //
// STYLES //
// ------ //

const Container = styled.div<TagProps>(({ position, index, hasCustomPosition }) => {
  const offsetTop: string | number = hasCustomPosition ? (Number(index) * 32) + (-1) : -1;
  const offsetBottom: string | number = hasCustomPosition ? (Number(index) * 32) + (-1) : -1;
  const offset: string | number = -1;
  const positions = position === 'top' ? { top: offsetTop, right: offset } : { bottom: offsetBottom, left: offset };

  return {
    position: 'absolute',
    zIndex: 1,
    pointerEvents: 'none',
    ...positions,
  };
});

const getRadius = (r: string, p?: Position) => {
  if (p === 'top') return `${r} 0 0 ${r}`;
  if (p === 'bottom') return `0 ${r} ${r} 0`;

  return r;
};

const CornerTag = styled(Tag)<TagProps>(({ theme, position }) => {
  const borderRadius = getRadius(theme.radius.pill, position);
  return { borderRadius };
});

// ----- //
// TYPES //
// ----- //

type Flag = ContentFlag | ContentConditionalFlag;
type Position = 'top' | 'bottom';

interface TagProps {
  position?: Position,
  index?: number,
  hasCustomPosition?: boolean
}

interface FlagProps extends TagProps {
  position: Position;
  color: ComponentProps<typeof Tag>['color'];
  // eslint-disable-next-line no-undef
  icon?: JSX.Element;
}

export default CardBadge;

import { ServerError } from 'common/apiService';
import { __t } from 'common/i18n';

export default function getAxiosError(error: ServerError): string {
  if (error?.response) {
    // const { status, statusText } = error.response
    // switch (status) {
    //   case 402:
    //     return statusText
    // }
    return error?.response?.data?.meta?.display_message || __t('errors.response.unknown');
  }
  if (error?.request) return __t('errors.no_response');
  if (!error?.request) return __t('errors.request');
  return __t('errors.unknown');
}

import styled from '@emotion/styled';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Flex, Icon, IconButton } from './styles';
import { IconAngleLeft, IconAngleRight } from './icons';
import theme, { responsiveProps, SpacingIndex } from './theme';
import { mainBg } from './styles/Main';

const Scroller: FC<ScrollerProps> = ({ children, arrows, cancelPadding, background }) => {
  const [state, setState] = useState<{ atLeft: boolean; atRight: boolean }>({
    atLeft: true,
    atRight: true,
  });
  const [showArrows, setShowArrows] = useState(false);
  const [overlayBottom, setOverlayBottom] = useState(0);
  const [supportScroll, setSupportScroll] = useState(false);

  useEffect(() => {
    setSupportScroll('scrollBehavior' in document?.documentElement?.style);
  }, []);

  const ref = useRef<HTMLDivElement>(null);
  const positionRef = useRef<{ value: number }>({ value: 0 });
  const prevState = useRef(state);

  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const handleOverlays = useCallback(() => {
    if (timerRef.current) clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      if (!ref?.current) return;
      handleScroll(ref.current);
    }, 100);
  }, []);

  const handleScroll = (target: EventTarget) => {
    const { scrollLeft, scrollWidth, offsetWidth } = target as HTMLDivElement;

    let atRight = scrollLeft > -edgeTolerance;
    let atLeft = scrollLeft + scrollWidth - offsetWidth < edgeTolerance;

    if (isTv(window.navigator.userAgent)) {
      atRight = false;
      atLeft = false;
    }

    if (prevState.current.atLeft !== atLeft || prevState.current.atRight !== atRight) {
      prevState.current = { atLeft, atRight };
      setState({ atLeft, atRight });
    }

    const centerRefEl = ref.current?.querySelector('[data-center-ref]');
    if (!centerRefEl) return;
    const bottom = centerRefEl.nextElementSibling?.clientHeight || 0;
    setOverlayBottom(bottom);
  };

  const isTv = (userAgent: string): boolean => {
    const tvRegex = /(SmartTV|SMART-TV|TV Browser|TV|BRAVIA|AppleTV|SmartHub|HbbTV|Tizen|WebOS|Android TV|GoogleTV)/i;
    return tvRegex.test(userAgent);
  };

  const recalibrate = () => {
    const container = ref?.current;
    if (!container) return;
    const left = container.scrollWidth / positionRef.current.value;
    if (supportScroll) container.scrollTo({ left });
    else container.scrollLeft = left;
  };

  const reset = () => {
    const container = ref?.current;
    if (!container) return;
    if (supportScroll) container.scrollTo({ left: 100000 });
    else container.scrollLeft = 100000;
  };

  const handleMouseOver = () => setShowArrows(true);
  const handleMouseLeave = () => setShowArrows(false);

  const handleClick = (direction: 'left' | 'right') => {
    const container = ref?.current;
    if (!container) return;

    const wrapper = container.querySelector('ul');
    const firstChild = container.querySelector('li');
    if (!wrapper || !firstChild) return;

    const { scrollLeft, scrollWidth } = container;
    const { offsetWidth: pageWidth } = wrapper;
    const { offsetWidth: childWidth } = firstChild;

    if (supportScroll) {
      const amount = pageWidth - childWidth;
      const left = direction === 'left' ? scrollLeft - amount : scrollLeft + amount;
      positionRef.current.value = scrollWidth / left;
      container.scrollTo({ left, behavior: 'smooth' });
    } else {
      container.scrollLeft = direction === 'left' ? scrollLeft - childWidth : scrollLeft + childWidth;
    }
  };

  useEffect(() => {
    if (!arrows) return;

    handleOverlays();

    const resizeHandlers = () => {
      handleOverlays();
      recalibrate();
    };
    window.addEventListener('resize', resizeHandlers);

    return () => window.removeEventListener('resize', resizeHandlers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrows, handleOverlays]);

  useEffect(() => {
    reset();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  return (
    <Container onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
      <Overlays cancelPadding={cancelPadding} right={!state.atRight} left={!state.atLeft} showArrows={showArrows} background={background}>
        <OverlayRight>
          <NavArrow right bottom={overlayBottom} onClick={handleClick} disabled={!showArrows || state.atRight} />
        </OverlayRight>
        <OverlayLeft>
          <NavArrow left bottom={overlayBottom} onClick={handleClick} disabled={!showArrows || state.atLeft} />
        </OverlayLeft>
      </Overlays>

      <ScrollWrapper ref={ref} cancelPadding={cancelPadding} onScroll={handleOverlays}>
        {children}
      </ScrollWrapper>
    </Container>
  );
};

const edgeTolerance = 2;

export const NavArrow = ({ bottom = 0, onClick, left, right, ...rest }: NavArrowProps) => {
  const icon = left ? <IconAngleLeft /> : <IconAngleRight />;
  const ariaLabel = left ? 'next' : 'previous';

  return (
    <ArrowButton
      style={{ marginBottom: bottom }}
      variant="flat"
      onClick={() => onClick?.(left ? 'left' : 'right')}
      left={left}
      right={right}
      aria-label={ariaLabel}
      {...rest}
    >
      <Icon cursor='pointer'>{icon}</Icon>
    </ArrowButton>
  );
};

// ------ //
// STYLES //
// ------ //

const Container = styled.div(() => ({ position: 'relative' }));

const ScrollWrapper = styled.div<{ cancelPadding: ScrollerProps['cancelPadding'] }>(({ cancelPadding = 0, theme }) => {
  const negativeMarginStyles = theme.mixins.create(cancelPadding, (p) => ({
    marginLeft: `-${theme.spacings[p]}rem`,
    marginRight: `-${theme.spacings[p]}rem`,
  }));

  const listWidthStyles = theme.mixins.create(cancelPadding, (p) => {
    const negativeMargin = theme.spacings[p]; // negative margins of the container
    const listItemPadding = theme.spacings[1]; // padding of the first/last list item
    let excessListWidth = (negativeMargin - listItemPadding) * 2; // empty spaces ON EITHER SIDE of the list element
    if (excessListWidth === negativeMargin) {
      excessListWidth = excessListWidth * 4;
    }
    return { width: `calc(100% - ${excessListWidth}rem)` };
  });

  const listBeforeAfterWidthStyles = theme.mixins.create(cancelPadding, (p) => ({ minWidth: theme.spacing(p) }));

  return {
    overflowX: 'scroll',
    overflowY: 'hidden',
    scrollbarWidth: 'none',
    paddingTop: '0.125rem',
    paddingBottom: '0.125rem',
    ...negativeMarginStyles,
    '&::-webkit-scrollbar': {
      background: '0 0',
      height: 0,
    },
    '& > ul': {
      ...listWidthStyles,
      flexWrap: 'nowrap',
      '&::before, &::after': {
        content: '""',
        ...listBeforeAfterWidthStyles,
      },
    },
  };
});

const Overlays = styled.div<OverlaysProps>(({ theme, left, right, background = mainBg, cancelPadding = 0, showArrows }) => {
  const [bg] = theme.palette.background[background];

  const responsiveStyles = theme.mixins.create(cancelPadding, (p) => ({
    left: `-${theme.spacing(p)}`,
    right: `-${theme.spacing(p)}`,
  }));

  return {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      height: '100%',
      top: 0,
      zIndex: 2,
      display: 'flex',
      justifyContent: 'space-between',
      pointerEvents: 'none',
      ...responsiveStyles,
      [`${OverlayLeft}`]: {
        opacity: left && (cancelPadding || showArrows) ? 1 : 0,
        backgroundImage: `linear-gradient(90deg, ${theme.rgba(bg, 1)}, ${theme.rgba(bg, 0.85)} 30%, ${theme.rgba(bg, 0)})`,
      },
      [`${OverlayRight}`]: {
        opacity: right && (cancelPadding || showArrows) ? 1 : 0,
        backgroundImage: `linear-gradient(90deg, ${theme.rgba(bg, 0)}, ${theme.rgba(bg, 0.85)} 70%, ${theme.rgba(bg, 1)})`,
      },
    },
  };
});

const Overlay = styled(Flex)(() => ({
  opacity: 1,
  transition: 'opacity .2s ease',
}));

const OverlayLeft = styled(Overlay)(({ theme }) => ({
  paddingRight: theme.spacing(1),
}));

const OverlayRight = styled(Overlay)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}));

const ArrowButton = styled(IconButton)<ArrowButtonProps>(({ theme, left, right }) => ({
  padding: theme.spacing(2),
  zIndex: 2,
  opacity: 1,
  pointerEvents: 'auto',
  transform: 'translateX(0)',
  transition: 'transform .3s ease, opacity .3s ease, background-color .3s ease',
  [`${Icon}`]: {
    margin: '0 -.1em',
    // ...theme.font.responsiveSize(5),
    fontSize: theme.font.size(2),
  },
  '&:disabled': {
    opacity: 0,
    pointerEvents: 'none',
    transform: `translateX(${left ? '-50%' : right ? '50%' : 0})`,
  },
  '&:hover, &:focus': {
    backgroundColor: 'transparent',
    'svg path': {
      strokeWidth: 4,
    },
  },
}));

// ----- //
// TYPES //
// ----- //

interface ScrollerProps {
  background?: OverlaysProps['background'];
  arrows?: boolean;
  cancelPadding?: responsiveProps<SpacingIndex>;
}

type NavArrowProps = ArrowButtonProps & {
  bottom?: number;
  disabled?: boolean;
  onClick: (direction: 'left' | 'right') => void;
};

interface OverlaysProps {
  left?: boolean;
  right?: boolean;
  showArrows?: boolean;
  background?: keyof typeof theme.palette.background;
  cancelPadding: ScrollerProps['cancelPadding'];
}

interface ArrowButtonProps {
  left?: boolean;
  right?: boolean;
}

export default Scroller;

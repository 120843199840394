import styled from '@emotion/styled';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Collapse from 'common/Collapse';
import { Icon, Link } from 'common/styles';
import { IconDown } from 'common/icons';
import { useRouter } from 'next/router';

const Wrapper = styled.div(({ theme }) => ({
  height: '100%',
  minHeight: 66,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 0),
  marginRight: theme.spacing(2),
  position: 'relative',

}));

const DropDownCollapse = styled(Collapse)(() => ({
  position: 'absolute',
  right: 0,
  minWidth: '400px',
  maxWidth: '400px',
  top: '61px',
}));

export type DropdownLinkProps = {
  onOpen?: () => any,
  onClose?: () => any,
  href: string,
  title: string,
  children?: React.ReactNode,
};

export default function DropdownLink({ onOpen, onClose, href, title, children }) {
  const router = useRouter();
  const [hover, setHover] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const anchorRef = useRef<HTMLAnchorElement>(null);
  const callbackRef = useRef({ onOpen, onClose });
  callbackRef.current = { onOpen, onClose };

  const handleOpen = useCallback(() => {
    setHover(true);
    callbackRef.current.onOpen?.();
  }, []);

  const handleClose = useCallback(() => {
    setHover(false);
    callbackRef.current.onClose?.();
  }, []);

  useEffect(() => {
    let id: any;
    const container = containerRef.current;
    const anchor = anchorRef.current;
    const handleFocusout = () => {
      id = setTimeout(() => {
        if (!container?.contains(document.querySelector(':focus')))
          handleClose();
      });
    };
    container?.addEventListener('focusout', handleFocusout);
    anchor?.addEventListener('focus', handleOpen);
    return () => {
      clearTimeout(id);
      container?.removeEventListener('focusout', handleFocusout);
      anchor?.removeEventListener('focus', handleOpen);
    };
  }, [handleClose, handleOpen]);
  return (
    <Wrapper
      className='desktop-only'
      ref={containerRef}
      onMouseLeave={handleClose}
    >
      <DropDownInnerLink href={href} ref={anchorRef} onMouseEnter={handleOpen} active={router.asPath.includes(href)} >
        {title}<Icon size="small" cursor="pointer"><IconDown /></Icon>
      </DropDownInnerLink>
      <DropDownCollapse open={hover}>
        {children}
      </DropDownCollapse>
    </Wrapper>
  );
}

const DropDownInnerLink = styled(Link)<{active ?: boolean}>(({ active }) => ({
  borderBottom: active ? '1px solid hsl(349, 66%, 40%)' : '1px solid transparent',
  padding: '0.5rem 0rem',
  '&:hover': {
    borderBottom: '1px solid hsl(349, 66%, 40%)',
  },
}));

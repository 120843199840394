import React from 'react';
import { __t } from './i18n';
import { Text } from './styles';
import { ValidationError } from './utils';
import { SpacingProps } from './withStyleSystem/withSpacingStyles';

type ErrorMessageProps = {
  message?: string | null | ValidationError,
  field?: string;
  children?: React.ReactNode;
} & SpacingProps;

export default function ErrorMessage({ message, field, children, ...rest }: ErrorMessageProps) {
  const errorMessage = message && getErrorMessage(message, field);

  return (
    <Text color="error" variant="body2" {...rest}>
      {errorMessage || children}
    </Text>
  );
}

function getErrorMessage(error: ValidationError | string, field = ''): string {
  if (Array.isArray(error)) {
    const [message, options] = error;
    return __t(message, { field, ...options });
  }
  return error;
}

/* eslint-disable no-restricted-syntax */
const th = ['',
  'هزار',
  'میلیون',
  'میلیارد',
];
const dg = [
  'صفر',
  'یک',
  'دو',
  'سه',
  'چهار',
  'پنج',
  'شش',
  'هفت',
  'هشت',
  'نه',
];
const tn = [
  'ده',
  'یازده',
  'دوازده',
  'سیزده',
  'چهارده',
  'پانزده',
  'شانزده',
  'هفده',
  'هجده',
  'نوزده',
];
const t2 = [
  'بیست',
  'سی',
  'چهل',
  'پنجاه',
  'شصت',
  'هفتاد',
  'هشتاد',
  'نود',
];
const t3 = [
  'صد',
  'دویست',
  'سی صد',
  'چهار صد',
  'پانصد',
  'ششصد',
  'هفت صد',
  'هشت صد',
  'نهصد',
];

export default function numberToPersianWords(input: number, options: { nth?: boolean; } = {}): string {
  const { nth = false } = options;
  if (nth && input < 0) throw 'only positive numbers are accepted when nth is true';
  const s: string = Math.abs(input).toString();
  let x = s.indexOf('.');
  if (x === -1) x = s.length;
  if (x > 21) throw 'too big';
  const n = s.split('')?.map((v) => Number(v));
  let str = '';

  function t2ToStr(d2, d1) {
    if (!d2) return d1 ? dg[d1] : '';
    else if (d2 === 1) {
      return tn[d1];
    }
    const s = t2[d2 - 2];
    if (d1)
      return `${s} و ${dg[d1]}`;
    return s;
  }
  function t3ToStr(d3, d2, d1) {
    if (!d3) return t2ToStr(d2, d1);

    const s = t3[d3 - 1];
    if (!d2 && !d1)
      return s;
    return `${s} و ${t2ToStr(d2, d1)}`;
  }
  for (let i = (x - 1) % 3 - 2; i < x; i += 3) {
    const hasNumber = n[i] || n[i + 1] || n[i + 2];
    if (i > 0 && hasNumber)
      str += ' و ';
    const part = t3ToStr(n[i], n[i + 1], n[i + 2]);
    const p = (x - i) / 3;
    if (p > 1) {
      if (part === 'یک' && p === 2)
        str = str + th[((x - i) / 3) - 1];
      else if (hasNumber)
        str = `${str + part} ${th[((x - i) / 3) - 1]}`;
    } else str += part;
  }

  if (input < 0) str = 'منفی ' + str;
  if (nth) {
    if (str === 'یک') str = 'اول';
    else if (str.endsWith('سه')) str = str.slice(0, str.length - 1) + 'وم';
    else if (str.endsWith('ی')) str += ' ام';
    else str += 'م';
  }

  return str;
}

import styled from '@emotion/styled';
import withStyleSystem from 'common/withStyleSystem';

export const Divider = withStyleSystem(styled.div(({ theme }) => ({
  color: theme.palette.foreground[900][0],
  borderBottom: '1px solid',
  opacity: .2,
})));

export default Divider;

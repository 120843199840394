const IconHamburger = () => {
  return (
    <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="23" y1="1" x2="1" y2="0.999998" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <line x1="23" y1="9" x2="1" y2="9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <line x1="23" y1="17" x2="1" y2="17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

export default IconHamburger;

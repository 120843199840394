import { Avatar } from 'common/styles';
import { MultiProfileSelectorProps, ProfileAvatar, User } from 'common/apiService';
import { profileShortInfo } from 'pages/profile/pin';
import { SpacingIndex, responsiveProps } from 'common/theme';
import { Size } from 'common/styles/Icon';

const UserAvatar = ({ user, profileInfo, size, hasMargin, image }:
  {
    user?: User | null, profileInfo?: profileShortInfo | MultiProfileSelectorProps | null,
    size: Size, hasMargin?: boolean, image?: string | ProfileAvatar,
  }) => {
  const sizes = {
    huge: '5rem',
    large: '4rem',
    small: '2rem',
  };

  const ml: responsiveProps<string | SpacingIndex> = { xs: 1, md: 0 };
  const mb: responsiveProps<string | SpacingIndex> = { xs: 0, md: 2 };
  const imagePath = image && typeof image === 'object' ? image?.image_path : image ? String(image) : undefined;
  const profileInfoAvatar = profileInfo?.avatar && typeof profileInfo?.avatar === 'object' ? profileInfo?.avatar?.image_path : profileInfo?.avatar ? String(profileInfo?.avatar) : undefined;
  const imageSrc = user?.avatar || profileInfoAvatar || imagePath;
  return <Avatar size={sizes[size]} image={imageSrc}
    ml={hasMargin ? ml : undefined} mb={hasMargin ? mb : undefined} />;
};

export default UserAvatar;

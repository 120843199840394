import styled from '@emotion/styled';
import withStyleSystem from 'common/withStyleSystem';
import Button from './Button';
import Icon from './Icon';
import { SpinnerIcon } from './Spinner';

const IconButton = styled(Button)<{ round?: boolean; isPlay?: boolean }>(({ theme, round, isPlay }) => ({
  padding: theme?.spacing(0),
  border: 'none',
  borderRadius: round ? theme.radius.pill : theme.radius.default,
  lineHeight: theme.font.lineHeight.compact,
  transition: 'transform 200ms linear',
  [`${Icon}, ${SpinnerIcon}`]: {
    display: 'block',
    margin: 0,
  },
  '& svg': {
    pointerEvents: 'none',
  },
  '&:hover, &:active, &:focus': {
    backgroundColor: isPlay ? theme.palette.primary[800][0] : undefined,
    transform: isPlay ? 'scale(1.1)' : undefined,
  },
}));

export default withStyleSystem(IconButton);

import styled from '@emotion/styled';
import React, { CSSProperties } from 'react';
import { IconCloseCircle } from './icons';
import { Icon } from './styles';

function TooltipStatic(
  { open, children, bgNumber = 900, onCloseEvent, style }: {
    bgNumber: number, children?: React.ReactNode,
    open: boolean, onCloseEvent: () => void,
    style?: CSSProperties
  },
) {
  return (
    <ToolTipWrapper open={open} bgNumber={bgNumber} id="#headerTooltip" style={style}>
      <div style={{ position: 'relative', width: '100%', display: 'flex' }}>
        <ToolTipAngel bgNumber={bgNumber} />
        <ToolTipBody bgNumber={bgNumber}>
          {children}
          <Icon onClick={() => onCloseEvent()} mr={1} cursor="pointer"><IconCloseCircle /></Icon>
        </ToolTipBody>
      </div>
    </ToolTipWrapper>
  );
}

const ToolTipWrapper = styled.div<{ open: boolean, bgNumber: number }>(({ theme, open, bgNumber }) => {
  return {
    display: open ? 'flex' : 'none',
    backgroundColor: theme.palette.warning[bgNumber][0],
    color: theme.palette.warning[bgNumber][1],
    // position: 'absolute',
    width: 'max-content',
    borderRadius: '24px',
    marginTop: '16px',
    padding: '8px 16px',
    fontWeight: theme.font.weight.regular,
    fontSize: '16px',
    border: `1px solid ${theme.palette.warning[bgNumber][1]}`,
  };
});

const ToolTipAngel = styled.div<{ bgNumber: number }>(({ theme, bgNumber }) => ({
  width: '12px',
  height: '12px',
  position: 'absolute',
  left: '6px',
  top: '-15px',
  transform: 'rotate(45deg)',
  backgroundColor: theme.palette.warning[bgNumber][0],
  borderTop: `1px solid ${theme.palette.warning[bgNumber][1]}`,
  borderBottom: '0px',
  borderLeft: `1px solid ${theme.palette.warning[bgNumber][1]}`,
  borderRight: '0px',
}));

const ToolTipBody = styled.div<{ bgNumber: number }>(({ theme, bgNumber }) => ({
  fontSize: '1rem',
  textAlign: 'center',
  margin: 'auto',
  width: '100%',

  [theme.breakpoints.down('lg')]: {
    fontSize: '14px',
  },
  'svg, circle': {
    stroke: theme.palette.warning[bgNumber][1],
  },
}));

export default TooltipStatic;

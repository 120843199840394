import styled from '@emotion/styled';
import { fetchSearchResult } from 'common/apiService';
import { IconSearch } from 'common/icons';
import { Box, Icon, IconButton } from 'common/styles';
import React, { useEffect, useRef, useState } from 'react';
import GlobalSearchResult from './GlobalSearchResult';
import SearchInput from './SearchInput';
import ClickAwayListener from 'react-click-away-listener';
import { headerHeight } from './Header';
import { useRouter } from 'next/router';
import { keyframes } from '@emotion/react';

const Container = styled.div(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'flex-end',
  marginRight: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    textAlign: 'inherit',
    position: 'relative',
  },
}));

const InputContainer = styled.div<{ open?: boolean }>(({ theme }) => ({
  position: 'absolute',
  top: headerHeight,
  width: '100%',
  padding: theme.spacing(0, 2),
  left: 0,
  [theme.breakpoints.up('md')]: {
    padding: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
}));

const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-.5em);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const SearchResultWrapper = styled(Box)(() => ({
  position: 'absolute',
  top: '100%',
  right: 0,
  width: '100%',
  maxHeight: 'calc(100vh - 7.5rem)',
  animation: `${slideDown} .3s ease`,
}));

const GlobalSearch = ({ onOpen }: { onOpen: (open: boolean) => void }) => {
  const [open, setOpen] = useState(false);
  const [result, setResult] = useState(null);
  const [query, setQuery] = useState('');
  const { asPath } = useRouter();

  const openRef = useRef(open);

  const handleChange = (value: string) => {
    if (query !== value) {
      setQuery(value);
    }
  };

  const router = useRouter();
  const handleReturn = (value: string) => {
    router.push(`/contents?query=${value}`);
    setQuery('');
    setOpen(false);
  };

  useEffect(() => {
    setOpen(false);
  }, [asPath]);

  useEffect(() => {
    if (openRef.current !== open) {
      openRef.current = open;
      onOpen(open);
      setResult(null);
      setQuery('');
    }
  }, [onOpen, open]);

  useEffect(() => {
    if (query === '') { setResult(null); }
    if (query?.length >= 3) {
      const timeoutId = setTimeout(() => {
        fetchSearchResult({ query }).then(({ data }) => {
          setResult(data);
        });
      }, 500);
      return () => { clearTimeout(timeoutId); };
    }
  }, [query]);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Container>
        <IconButtonContainer>
          <Icon size="large" onClick={() => setOpen(!open)} cursor="pointer"><IconSearch /></Icon>
        </IconButtonContainer>

        {open && (
          <InputContainer>
            <SearchInput value={query} onChange={handleChange} onReturn={handleReturn} onClose={() => setOpen(false)} inputSize="small" />
            {result && (
              <SearchResultWrapper pt=".25rem" px={{ xs: 2, md: 0 }}>
                <GlobalSearchResult result={result} />
              </SearchResultWrapper>
            )}
          </InputContainer>
        )}
      </Container>
    </ClickAwayListener>
  );
};

const IconButtonContainer = styled(IconButton)(() => ({
  '&:hover': {
    backgroundColor: 'transparent !important',
    cursor: 'pointer',
  },
}));

export default GlobalSearch;

import { useEffect } from 'react';
import Router from 'next/router';

const idxCache: { scroll: number; asPath: string; }[] = [];

/**
 * ### manual scroll restoration when navigating backward
 * **note:** should only be used in _app!
 */
export default function useScrollRestore() {
  useEffect(() => {
    if (history.state?.idx === undefined) {
      return;
    }
    history.scrollRestoration = 'manual';
    let prevIdx: number = history.state?.idx ?? 0;
    let currentPath = location.pathname + location.search;
    Router.events.on('beforeHistoryChange', () => {
      idxCache[prevIdx] = { scroll: document.documentElement.scrollTop || window.scrollY, asPath: currentPath };
    });
    Router.events.on('routeChangeComplete', () => {
      currentPath = location.pathname + location.search;
      const idx: number = history.state?.idx;
      const wasBackward = history.state?.idx < prevIdx;
      if (wasBackward && idxCache?.[idx]?.asPath === currentPath) {
        document.documentElement.scrollTop = idxCache[idx].scroll;
      }
      prevIdx = history.state?.idx;
    });
  }, []);
}

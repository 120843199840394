import styled from '@emotion/styled';
import { useUser } from 'common/CacheProvider';
import { NextRouter, useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { SystemMessage } from 'common/Header/SystemMessage';
import lscache from 'lscache';

export const mainBg = 800;

const MainContainer = styled.main<{ system_message: SystemMessage | null, router: NextRouter }>(({ theme, system_message, router }) => {
  return {
    position: 'relative',
    backgroundColor: theme.palette.background[mainBg][0],
    color: theme.palette.background[mainBg][1],
    overflowX: 'hidden',
    fontFamily: theme.font.family.default,
    lineHeight: theme.font.lineHeight.normal,
    ...theme.font.responsiveSize(1),
    direction: theme.direction,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    '& .mobile-only': {
      [theme.breakpoints.up('md')]: { display: 'none' },
    },
    '& .desktop-only': {
      [theme.breakpoints.down('md')]: { display: 'none' },
    },
    '& .en': {
      fontFamily: theme.font.family.english,
    },
    marginTop: system_message && !system_message?.isClosedBefore && !router.asPath.includes('play') ? '72px' : 0,
    [theme.breakpoints.up('md')]: {
      marginTop: system_message && !system_message?.isClosedBefore && !router.asPath.includes('play') ? '48px' : 0,
    },

  };
});

const Main = ({ children }) => {
  const [system_message, setSystemMessage] = useState<SystemMessage | null>(null);
  const { authorized, revalidate, loaded } = useUser();
  const router = useRouter();
  useEffect(() => {
    setSystemMessage(lscache.get('system_message'));
    window.addEventListener('storage', handleSystemMessage);

    const routes = ['/user/edit', '/user/password', '/user', '/packages', '/packages/successGift', '/packages/successPayment'];
    if (loaded && authorized && !routes.includes(router.pathname)) {
      revalidate();
    }
    return () => {
      window.removeEventListener('storage', handleSystemMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorized, loaded]);

  const handleSystemMessage = () => {
    setSystemMessage(lscache.get('system_message'));
  };

  return <MainContainer system_message={system_message} router={router}>{children}</MainContainer>;
};

export default Main;

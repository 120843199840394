import React, { FC } from 'react';
import { Flex, FlexJustify, Row } from './styles';
import Rating from './Rating';
import { SpacingProps } from './withStyleSystem/withSpacingStyles';
import { responsiveProps } from './theme';

interface ContentRatingsProps {
  imdb_rank_percent?: number;
  rate_percentage?: number;
  styleSystem?: SpacingProps;
  split?: boolean;
  contained?: boolean;
  coming_soon?: string;
  justify?: responsiveProps<FlexJustify> | undefined
}

export default function ContentRatings({
  rate_percentage: rate,
  imdb_rank_percent: imdb,
  styleSystem,
  split,
  contained = false,
  coming_soon,
  justify = 'space-between',
}: ContentRatingsProps) {
  const Container: FC = (props) => {
    if (split) return <Flex style={{ width: '100%' }} justify={justify} {...props} {...styleSystem} />;
    return <Row style={{ paddingBottom: coming_soon ? '0.8rem' : undefined }} nowrap {...props} {...styleSystem} />;
  };

  return (
    <Container>
      {!coming_soon && !!rate && <Rating type="rate" rate={rate} contained={contained} ml={split && justify === 'flex-start' ? '1rem' : 0} />}
      {!!imdb && <Rating type="imdb" rate={imdb} contained={contained} />}
    </Container>
  );
}

export default function IconTvWeb() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.2632 17.2219H18.5114C18.7347 16.3659 18.8554 15.4863 18.8709 14.6016H16.2632V17.2219Z" fill="#F1F1F1"/>
      <path d="M16.2632 20.3403C17.1181 20.1697 17.8776 19.1737 18.3542 17.7461H16.2632V20.3403Z" fill="#F1F1F1"/>
      <path d="M18.9152 17.7461C18.67 18.6415 18.2163 19.4655 17.5913 20.1503C18.9585 19.7709 20.1485 18.9195 20.9516 17.7461H18.9152Z" fill="#F1F1F1"/>
      <path d="M22.0016 14.6016H19.3936C19.3808 15.4847 19.2697 16.3636 19.0625 17.2219H21.2754C21.7145 16.4159 21.963 15.5193 22.0016 14.6016Z" fill="#F1F1F1"/>
      <path d="M17.5835 8.53125C18.2085 9.21599 18.6622 10.0401 18.9073 10.9354H20.944C20.1408 9.76198 18.9508 8.91059 17.5835 8.53125Z" fill="#F1F1F1"/>
      <path d="M16.2632 14.0735H18.8709C18.8554 13.1888 18.7347 12.3091 18.5114 11.4531H16.2632V14.0735Z" fill="#F1F1F1"/>
      <path d="M22.0016 14.0735C21.963 13.1557 21.7145 12.2591 21.2754 11.4531H19.0625C19.2697 12.3115 19.3808 13.1904 19.3936 14.0735H22.0016Z" fill="#F1F1F1"/>
      <path d="M16.2632 8.33594V10.9301H18.3542C17.8776 9.50357 17.1181 8.50757 16.2632 8.33594Z" fill="#F1F1F1"/>
      <path d="M15.7366 14.6016H13.1289C13.1445 15.4863 13.2652 16.3659 13.4885 17.2219H15.7366V14.6016Z" fill="#F1F1F1"/>
      <path d="M15.7366 11.4531H13.4885C13.2652 12.3091 13.1445 13.1888 13.1289 14.0735H15.7366V11.4531Z" fill="#F1F1F1"/>
      <path d="M15.738 20.3403V17.7461H13.647C14.1235 19.1726 14.8831 20.1686 15.738 20.3403Z" fill="#F1F1F1"/>
      <path d="M15.738 8.33594C14.8831 8.50652 14.1235 9.50252 13.647 10.9301H15.738V8.33594Z" fill="#F1F1F1"/>
      <path d="M10 14.0735H12.608C12.6208 13.1904 12.7319 12.3115 12.9391 11.4531H10.7264C10.2871 12.2591 10.0386 13.1557 10 14.0735Z" fill="#F1F1F1"/>
      <path d="M14.4071 8.53125C13.04 8.91071 11.85 9.76207 11.0469 10.9354H13.0836C13.3285 10.04 13.7822 9.21595 14.4071 8.53125Z" fill="#F1F1F1"/>
      <path d="M12.9391 17.2219C12.7319 16.3636 12.6208 15.4847 12.608 14.6016H10C10.0386 15.5194 10.2871 16.416 10.7264 17.2219H12.9391Z" fill="#F1F1F1"/>
      <path d="M14.4071 20.1503C13.7822 19.4656 13.3285 18.6415 13.0836 17.7461H11.0469C11.85 18.9195 13.04 19.7708 14.4071 20.1503Z" fill="#F1F1F1"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M29.3333 6.33333H2.66667C1.93029 6.33333 1.33333 6.93029 1.33333 7.66667V21C1.33333 21.7364 1.93029 22.3333 2.66667 22.3333H29.3333C30.0697 22.3333 30.6667 21.7364 30.6667 21V7.66667C30.6667 6.93029 30.0697 6.33333 29.3333 6.33333ZM2.66667 5C1.19391 5 0 6.19391 0 7.66667V21C0 22.4728 1.19391 23.6667 2.66667 23.6667H29.3333C30.8061 23.6667 32 22.4728 32 21V7.66667C32 6.19391 30.8061 5 29.3333 5H2.66667Z" fill="#F1F1F1"/>
      <path d="M5.3335 26.9987C5.3335 26.6305 5.63197 26.332 6.00016 26.332H26.0002C26.3684 26.332 26.6668 26.6305 26.6668 26.9987C26.6668 27.3669 26.3684 27.6654 26.0002 27.6654H6.00016C5.63197 27.6654 5.3335 27.3669 5.3335 26.9987Z" fill="#F1F1F1"/>
    </svg>
  );
}

import styled from '@emotion/styled';
import { GlobalDataType } from 'common/apiService';
import { Link, Paper, Placeholder} from 'common/styles';
// import ContentCard from 'common/ContentCard';

const DropdownPaper = styled(Paper)(() => ({
  display: 'flex',
  justifyContent: 'start',
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderRadius: '8px',
  // padding: theme.spacing(1.5, 1, 0.5, 1),
  padding: '12px 8px 4px 8px',
  margin: 0,
  height: 176,
  maxHeight: '80vh',
}));

const Section = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',

  '&.genre': {
    width: '100%',
  },
  // '&.category': {
  //   width: '35%',
  // },
  // '&.content': {
  //   width: '30%',
  //   maxWidth: 350,
  // },
}));

export const menuBg = 500;
const LinksList = styled.ul(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: 176,
  flexWrap: 'wrap',
}));
const Item = styled.li(({ theme }) => ({
  padding: '8px',
  height: '24px',
  marginBottom: '8px',
  width: '96px',
  alignItems: 'center',
  display: 'flex',
  '&:hover': {
    borderRadius: '4px',
    color: theme.palette.background[menuBg][1],
    backgroundColor: theme.palette.background[menuBg][0],
  },
}));

const LinksListLoading = () => <>{[4, 5, 6, 5, 4, 5, 6].map((w, i) => <Item key={`${w}-${i}`}><Placeholder width={w} /></Item>)}</>;

interface DropdownMenuProps {
  type: 'series' | 'single_video';
  data?: GlobalDataType;
  loading: boolean;
}
export default function DropdownMenu({ type = 'single_video', data = {}, loading }: DropdownMenuProps) {
  const { categories } = data;

  // const contents = type === 'single_video' ? newMovies : newSeries;
  // const defaultCategories = categories?.find((item) => item?.type === 'default')?.items;
  const genreCategories = categories?.find((item) => item?.type === 'genre')?.items;

  return (
    <DropdownPaper>
      <Section className="genre">
        {/* <Text variant="h2">{__t('content.genre')}</Text> */}
        <LinksList>
          {loading
            ? <LinksListLoading />
            : (
              genreCategories?.map((genre) => (
                <Item key={genre.id}>
                  <Link href={`/contents?types=${type}&order=latest&categories=${genre.id}`}>
                    {genre.title}
                  </Link>
                </Item>
              ))
            )}
        </LinksList>
      </Section>
      {/* <Section className="category">
        <Text variant="h2">{__t('common.category')}</Text>
        <LinksList>{loading
          ? <LinksListLoading />
          : (defaultCategories?.map((category) => (
            <Item key={category.id}>
              <Link href={`/categories/${category.short_id}`}>
                {category.title}
              </Link>
            </Item>
          ))
          )}
        </LinksList>
      </Section>
      <Section className="content">
        <Text variant="h2">{__t('common.latests')}</Text>
        <Row nowrap cols={2} mt={1}>
          {loading
            ? [1, 2].map((i) => <Placeholder key={i} as={Card} fullWidth block />)
            : contents?.map((content) => <ContentCard key={content.id} content={content} />)}
        </Row>
      </Section> */}
    </DropdownPaper>
  );
}

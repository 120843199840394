import styled from '@emotion/styled';
import { ComponentPropsWithoutRef, FC } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { CSSTransition } from 'react-transition-group';
import { IconClose } from './icons';
import Portal from './Portal';
import { BackDrop, Icon, IconButton, Paper, Text } from './styles';
import useScrollLock from './useScrollLock';

const DialogBackDrop = styled(BackDrop)<{ backDropBackColor?: string }>(({ backDropBackColor }) => ({
  background: backDropBackColor,
  '&.dialog-enter': {
    opacity: 0,
  },
  '&.dialog-enter-active': {
    opacity: 1,
    transition: 'opacity 300ms ease',
  },
  '&.dialog-exit': {
    opacity: 1,
  },
  '&.dialog-exit-active': {
    opacity: 0,
    transition: 'opacity 300ms ease',
  },
}));

const DialogPaper = styled(Paper)<{ backColor?: string, dropShadow?: string, customWidth?: string, radius?: string, hasWidth?: boolean }>(({ theme, width = 'xs', backColor, dropShadow, customWidth, radius, hasWidth }) => ({
  background: backColor ? backColor : undefined,
  padding: '0 !important',
  position: 'relative',
  zIndex: theme.zIndex.modal,
  width: customWidth ? customWidth : theme.breakpoints[width],
  maxHeight: '100%',
  maxWidth: '100%',
  overflowY: 'auto',
  flexShrink: 0,
  borderRadius: radius ? radius : theme.radius.full,
  boxShadow: dropShadow,
  '.dialog-enter &': {
    opacity: 0,
    transform: 'translateY(5%)',
  },
  '.dialog-enter-active &': {
    opacity: 1,
    transform: 'translateY(0)',
    transition: 'transform 300ms ease, opacity 300ms ease',
  },
  '.dialog-exit &': {
    opacity: 1,
  },
  '.dialog-exit-active &': {
    opacity: 0,
    transform: 'translateY(5%)',
    transition: 'transform 300ms ease, opacity 300ms ease',
  },
  [theme.breakpoints.down('xs')]: {
    width: hasWidth ? customWidth : '100%',
    overflow: 'hidden',
  },
}));

const DialogBody = styled.div(({ theme }) => ({
  padding: theme.spacing(3),
}));

const DialogHeader = styled.div<{ headerBackColor?: string }>(({ headerBackColor, theme }) => ({
  background: headerBackColor ? headerBackColor : theme.palette.background[600][0],
  position: 'relative',
  padding: theme.spacing(3),
}));

const CloseButtonWrapper = styled(IconButton)(({ disabled }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  padding: '.75rem',
  '&:hover': {
    background: 'transparent',
  },
  '&:disabled': disabled && {
    pointerEvents: 'none',
  },
}));

export const CloseButton = ({ disabled, onClick: handleClick }) => (
  <CloseButtonWrapper disabled={disabled} onClick={handleClick}>
    <Icon cursor="pointer">
      <IconClose />
    </Icon>
  </CloseButtonWrapper>
);

const Dialog: FC<{
  open: boolean; onClose?: () => void; backColor?: string,
  dropShadow?: string;
  customWidth?: string,
  hasWidth?: boolean,
  backDropBackColor?: string,
  headerBackColor?: string,
  loading?: boolean
} & ComponentPropsWithoutRef<typeof DialogPaper>> = ({
  open,
  onClose: handleClose,
  children,
  backColor,
  dropShadow,
  customWidth,
  backDropBackColor,
  title,
  headerBackColor,
  loading,
  hasWidth,
  ...rest
}) => {
  useScrollLock(open);
  return (
    <Portal>
      <CSSTransition timeout={300} in={open} classNames="dialog" unmountOnExit>
        <DialogBackDrop backDropBackColor={backDropBackColor}>
          <ClickAwayListener onClickAway={() => !loading && handleClose?.()}>
            <DialogPaper p={{ xs: 3, md: 4 }} py={{ xs: 4, md: 6 }} backColor={backColor}
              dropShadow={dropShadow} customWidth={customWidth} {...rest} hasWidth={hasWidth}>
              {title ? <DialogHeader headerBackColor={headerBackColor}>
                <Text variant="body1" align="center">{title}</Text>
                {handleClose && <CloseButton disabled={loading} onClick={handleClose} />}
              </DialogHeader>
                : <> {handleClose && <CloseButton disabled={loading} onClick={handleClose} />}</>}
              <DialogBody>
                {children}
              </DialogBody>
            </DialogPaper>
          </ClickAwayListener>
        </DialogBackDrop>
      </CSSTransition>
    </Portal>
  );
};

export default Dialog;

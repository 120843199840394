import styled from '@emotion/styled';
import withStyleSystem from 'common/withStyleSystem';
import { variants } from './Text';

const Label = styled.label(({ theme }) => ({
  ...theme.font.responsiveSize(variants.body2.fontSize),
  lineHeight: theme.font.lineHeight.compact,
  display: 'block',
}));

export default withStyleSystem(Label);

import React from 'react';

const IconCloseCircle = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="7.5" stroke="#F54768" />
      <path fillRule="evenodd" clipRule="evenodd" d="M5.13212 5.132C4.95613 5.30799 4.95613 5.59334 5.13212 5.76933L7.36273 7.99994L5.132 10.2307C4.956 10.4067 4.956 10.692 5.132 10.868C5.30799 11.044 5.59334 11.044 5.76933 10.868L8.00006 8.63727L10.2308 10.868C10.4068 11.044 10.6921 11.044 10.8681 10.868C11.0441 10.692 11.0441 10.4067 10.8681 10.2307L8.6374 7.99994L10.868 5.76933C11.044 5.59334 11.044 5.30799 10.868 5.132C10.692 4.956 10.4067 4.95601 10.2307 5.132L8.00006 7.3626L5.76946 5.132C5.59346 4.956 5.30812 4.956 5.13212 5.132Z" fill="#F54768" />
    </svg>
  );
};

export default IconCloseCircle;

import React from 'react';

export default function IconConfirm() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM38.1357 17.3474C39.1881 16.0677 39.004 14.1772 37.7243 13.1247C36.4447 12.0723 34.5541 12.2564 33.5017 13.5361L20.7447 29.0467L15.1952 23.1744C14.0572 21.9702 12.1585 21.9165 10.9543 23.0545C9.75009 24.1925 9.69642 26.0912 10.8344 27.2954L18.7204 35.6402C19.3118 36.266 20.1435 36.6075 21.0041 36.5779C21.8646 36.5483 22.6709 36.1503 23.2178 35.4853L38.1357 17.3474Z"
        fill="currentColor"
      />
    </svg>
  );
}

import styled from '@emotion/styled';
import { ColorKey } from 'common/theme';
import withStyleSystem from 'common/withStyleSystem';
import { variants } from './Text';

export type TagProps = {
  href?: string;
  compact?: boolean;
  color?: TagColor;
  size?: 'default' | 'small';
}

export const tagColors: Record<TagColor, [ColorKey, number]> = {
  default: ['background', 500],
  primary: ['primary', 900],
  info: ['info', 100],
  sweet: ['sweet', 900],
  accent: ['accent', 900],
  accent2: ['accent', 800],
  accent3: ['accent', 700],
  warning: ['warning', 300],
  success: ['success', 900],
  success2: ['success', 700],
};

export type TagColor = 'default' | 'primary' | 'info' | 'sweet' | 'accent' | 'accent2' | 'accent3' | 'warning' | 'success' | 'success2';

const tagSizeMap = {
  default: variants.body2.fontSize,
  small: variants.body3.fontSize,
};

const Tag = styled.div<TagProps>(({ theme, compact, color = 'default', size = 'default' }) => {
  const [colorKey, intensity] = tagColors[color];
  const [bg, fg] = theme.palette[colorKey][intensity];

  const fontSize = tagSizeMap[size];

  return ({
    display: 'inline-block',
    backgroundColor: bg,
    color: fg,
    whiteSpace: 'nowrap',
    borderRadius: theme.radius.pill,
    padding: `${compact ? .15 : .25}em ${compact ? .5 : 1}em`,
    border: 'none',
    fontWeight: theme.font.weight.demibold,
    lineHeight: theme.font.lineHeight.normal,
    ...theme.font.responsiveSize(fontSize),
  });
});

export default withStyleSystem(Tag);

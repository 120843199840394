/* eslint-disable no-param-reassign */
const durationToWords = (duration: string, onlyMinute?: boolean): string => {
  const [hour, min] = duration?.substring(0, 5).split(':').map((d) => Number(d)) || [];
  if (!onlyMinute) {
    return (hour ? `${hour} ساعت` : '') + ((hour && min) ? ' و ' : '') + (min ? `${min} دقیقه` : '');
  }
  const allMinute = `${Number(hour) * 60 + Number(min)} دقیقه`;
  return allMinute;
};

export default durationToWords;

export const allSecondsToDurationWords = (seconds: number) => {
  const days = Math.floor(seconds / (24 * 60 * 60));
  seconds %= 24 * 60 * 60;

  const hours = Math.floor(seconds / (60 * 60));
  seconds %= 60 * 60;

  const minutes = Math.floor(seconds / 60);
  seconds %= 60;

  const duration = (days > 0 ? `${days} روز` : '') + (hours ? `${hours} ساعت` : '') + ((hours && minutes) ? ' و ' : '') + (minutes ? `${minutes} دقیقه` : '');

  return duration;
};

export const convertDate = (date: string) => {
  return new Date(date).toLocaleString('fa-IR', { year: 'numeric', month: '2-digit', day: '2-digit' });
};

import React from 'react';

export default function IconProfileAvatar() {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="32" cy="32" r="32" fill="#5C5C5C" />
      <path d="M32.0002 35.3346C37.8912 35.3346 42.6668 30.2605 42.6668 24.0013C42.6668 17.7421 37.8912 12.668 32.0002 12.668C26.1091 12.668 21.3335 17.7421 21.3335 24.0013C21.3335 30.2605 26.1091 35.3346 32.0002 35.3346Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10.2725 55.4949C11.4345 52.5268 13.2034 49.7996 15.5008 47.5021C19.8767 43.1263 25.8116 40.668 32 40.668C38.1884 40.668 44.1233 43.1263 48.4991 47.5021C50.7963 49.7993 52.5651 52.5262 53.7271 55.4939C48.0208 60.7741 40.3869 64.0011 31.9993 64.0011C23.6122 64.0011 15.9787 60.7745 10.2725 55.4949Z" fill="white" />
    </svg>
  );
}

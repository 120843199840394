import { FC, useEffect } from 'react';
// import theme from '../theme';
import { Box, Link } from 'common/styles';
// import Tooltip from 'common/Tooltip';
// import { __t } from 'common/i18n';
import { actionChangeTheme, useDispatch, useTheme } from 'common/CacheProvider';
import { useRouter } from 'next/router';
import { IconBrandFull, IconBrandCompact } from 'common/icons';

export default function MenuBrand({ hasProfile = true }: { hasProfile?: boolean }) {
  // const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  // const [openTooltip, setOpenTooltip] = useState(false);

  // useEffect(() => {
  //   handleHeaderTooltip();
  //   window.addEventListener('resize', handleHeaderTooltip);
  //   return () => {
  //     window.removeEventListener('resize', handleHeaderTooltip);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // const handleHeaderTooltip = () => {
  //   setOpenTooltip(Number(theme.breakpoints.md) > Number(window.innerWidth));
  // };

  return (
    <Box ml={{ md: 3, lg: 4 }}>
      {!hasProfile
        ? <>
          <BrandFull className="desktop-only" />
          <BrandCompact className="mobile-only" />
        </> : <Link href="/">
          <BrandFull className="desktop-only" />
          <BrandCompact className="mobile-only" />
          {/* <Tooltip
            disableMobileHover={openTooltip}
            anchor={referenceElement}
            placement="bottom-end"
            type="text"
          >
            {__t('common.go_to_home_page')}
          </Tooltip> */}
        </Link>}
    </Box>
  );
}

export const BrandFull: FC<{ className?: string; width?: string; height?: string; }> = (props) => {
  const { currentTheme } = useTheme();
  const dispatch = useDispatch();
  const router = useRouter();
  useEffect(() => {
    if (!(router.pathname === '/packages' || router.asPath.includes('scNnlM') || router.asPath.includes('tickets') || router.asPath.includes('dd-agreement') || router.asPath.includes('payment')) && currentTheme === 'light') {
      dispatch(actionChangeTheme('dark'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <IconBrandFull {...props} />;
};

export const BrandCompact: FC<{ className?: string; width?: string; height?: string; }> = (props) => {
  // const { currentTheme } = useTheme();
  return <IconBrandCompact {...props} />;
};

import * as React from 'react';
import * as ReactDOM from 'react-dom';

type PortalProps = {
  children?: React.ReactNode,
  container?: HTMLElement,
  disablePortal?: boolean,
};
function Portal({ children, container, disablePortal = false }: PortalProps) {
  const [mountNode, setMountNode] = React.useState<HTMLElement | null>(null);

  React.useEffect(() => {
    if (!disablePortal) {
      setMountNode(container || document.body);
    }
  }, [container, disablePortal]);

  if (disablePortal) return <>{children}</>;

  return mountNode
    ? ReactDOM.createPortal(children, mountNode)
    : mountNode;
}

export default Portal;

import { Icon, IconButton, Input, InputGroup } from './styles';
import { IconEyeClosed, IconEye } from './icons';
import React, { useState } from 'react';

const PasswordInput = React.forwardRef<HTMLInputElement>(
  function PasswordInput(props, ref) {
    const [show, setShow] = useState(false);

    const handleClick = () => {
      setShow(!show);
    };

    return (
      <InputGroup>
        <IconButton onClick={handleClick} type="button" tabIndex={-1}>
          <Icon cursor="pointer">{show ? <IconEye /> : <IconEyeClosed />}</Icon>
        </IconButton>
        <Input ref={ref} {...props} type={show ? 'text' : 'password'} />
      </InputGroup>
    );
  },
);

export default PasswordInput;

import React from 'react';

export default function IconDownloadApp() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M29.3333 6.33333H2.66667C1.93029 6.33333 1.33333 6.93029 1.33333 7.66667V21C1.33333 21.7364 1.93029 22.3333 2.66667 22.3333H29.3333C30.0697 22.3333 30.6667 21.7364 30.6667 21V7.66667C30.6667 6.93029 30.0697 6.33333 29.3333 6.33333ZM2.66667 5C1.19391 5 0 6.19391 0 7.66667V21C0 22.4728 1.19391 23.6667 2.66667 23.6667H29.3333C30.8061 23.6667 32 22.4728 32 21V7.66667C32 6.19391 30.8061 5 29.3333 5H2.66667Z" fill="#1C1C1C"/>
      <path d="M5.33301 26.9987C5.33301 26.6305 5.63148 26.332 5.99967 26.332H25.9997C26.3679 26.332 26.6663 26.6305 26.6663 26.9987C26.6663 27.3669 26.3679 27.6654 25.9997 27.6654H5.99967C5.63148 27.6654 5.33301 27.3669 5.33301 26.9987Z" fill="#1C1C1C"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M16.0003 9.66406C16.3685 9.66406 16.667 9.96254 16.667 10.3307V18.3307C16.667 18.6989 16.3685 18.9974 16.0003 18.9974C15.6321 18.9974 15.3337 18.6989 15.3337 18.3307V10.3307C15.3337 9.96254 15.6321 9.66406 16.0003 9.66406Z" fill="#1C1C1C"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.8004 15.9308C13.0213 15.6362 13.4391 15.5765 13.7337 15.7974L16.0004 17.4974L18.267 15.7974C18.5616 15.5765 18.9795 15.6362 19.2004 15.9308C19.4213 16.2253 19.3616 16.6432 19.067 16.8641L16.4004 18.8641C16.1633 19.0419 15.8374 19.0419 15.6004 18.8641L12.9337 16.8641C12.6391 16.6432 12.5795 16.2253 12.8004 15.9308Z" fill="#1C1C1C"/>
    </svg>
  );
}

import * as Sentry from '@sentry/nextjs';

import getConfig from 'next/config';
// eslint-disable-next-line no-undef
const { publicRuntimeConfig } = getConfig();
const SENTRY_CLIENT_DSN = publicRuntimeConfig.NEXT_PUBLIC_SENTRY_DSN;

if (SENTRY_CLIENT_DSN)
  Sentry.init({
    dsn: SENTRY_CLIENT_DSN,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    environment: publicRuntimeConfig.SENTRY_ENVIRONMENT,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
import React, { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import {  Flex } from '../styles';
import Footer  from './Footer';
import ClickAwayListener from 'react-click-away-listener';
import { BigFooterContainer, CloseButton, Container, DownloadButton, ElevatorButton, ElevatorContainer, Menu, MoreButton } from './Components';
import { useAgent } from '../CacheProvider';
import useScrollTop from '../utils/useScrollTop';

export default function MiniFooter({ alwaysShow, footerDisabled }: { alwaysShow?: boolean; footerDisabled?: boolean }) {
  const { isTvAgent } = useAgent();
  const [state, setState] = useState({
    miniFooter: !!alwaysShow,
    elevator: false,
    bigFooter: false,
  });
  const scrollTopRef = useRef(0);
  const { supportScroll, scrollToTop } = useScrollTop();

  const handleScrollToTop = (e: MouseEvent) => {
    e.stopPropagation();

    if (supportScroll) window.scrollTo({ top: 0, behavior: 'smooth' });
    else scrollToTop();
  };

  const handleScroll = useCallback(() => {
    if (alwaysShow) return;

    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop || window.scrollY;
    const pageHeight = document.documentElement.offsetHeight || document.body.offsetHeight;
    const { innerHeight: viewportHeight } = window;
    const footerEl = document.getElementById('app-footer');
    const footerHeight = footerEl?.offsetHeight || 0;

    const direction = scrollTopRef.current < scrollTop ? 'downward' : 'upwards';
    scrollTopRef.current = scrollTop;

    const elevatorTrigger = scrollTop > viewportHeight / 2;
    const hideTrigger = footerDisabled ? false : viewportHeight + scrollTop >= pageHeight - footerHeight;
    const miniFooter = direction === 'downward' && !hideTrigger;

    setState((prev) => ({
      ...prev,
      miniFooter,
      elevator: elevatorTrigger,
    }));
  }, [alwaysShow, footerDisabled]);

  useEffect(() => {
    if (!state.miniFooter) {
      setState((prev) => ({ ...prev, bigFooter: false }));
    }
  }, [state.miniFooter]);

  const handleFooterToggle = (e: MouseEvent, show?: boolean) => {
    e.stopPropagation();
    setState((prev) => ({ ...prev, bigFooter: show ?? !prev.bigFooter }));
  };

  const handleContainerClick = (e: any) => {
    if (e.target?.tagName === 'DIV') handleFooterToggle(e);
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <ClickAwayListener onClickAway={() => setState((prev) => ({ ...prev, bigFooter: false }))}>
      <Container isTvAgent={isTvAgent} show={state.miniFooter} onClick={(e) => handleContainerClick(e)}>
        <Flex p={2} justify="space-between" align="center">
          <ElevatorContainer justify="space-between" align="center" nowrap showElevator={state.elevator}>
            <ElevatorButton ml={{ xs: 2, md: 3 }} mr="-.25rem" onClick={handleScrollToTop} />
            <Menu />
          </ElevatorContainer>

          <Flex>
            <DownloadButton className="desktop-only" />
            <MoreButton ml="-.5rem" className="mobile-only" onClick={() => setState({ ...state, bigFooter: true })} />
          </Flex>
        </Flex>

        <BigFooterContainer show={state.bigFooter}>
          <CloseButton m="1rem .75rem" />
          <Footer />
        </BigFooterContainer>
      </Container>
    </ClickAwayListener>
  );
}

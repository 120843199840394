import styled from '@emotion/styled';
import { responsiveProps } from 'common/theme';
import withStyleSystem from 'common/withStyleSystem';
import { deepMerge } from 'common/utils';

export type FlexAlign = 'normal' | 'center' | 'flex-start' | 'flex-end' | 'stretch' | 'baseline';
export type FlexJustify = 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around';
export type FlexDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse';
export type FlexPosition = 'relative' | 'static' | 'absolute' | 'fixed' | 'sticky' | 'unset';

export type FlexProps = {
  justify?: responsiveProps<FlexJustify>,
  align?: responsiveProps<FlexAlign>,
  direction?: responsiveProps<FlexDirection>,
  nowrap?: responsiveProps<boolean>,
  fullHeight?: responsiveProps<boolean>,
  fullWidth?: responsiveProps<boolean>,
  position?: FlexPosition
};

const Flex = styled.div<FlexProps>(({ theme, justify, align, direction, nowrap, fullHeight, fullWidth, position }) => ({
  display: 'flex',
  position,
  ...deepMerge({},
    theme.mixins.create(fullHeight, (n) => (n ? { height: '100%' } : {})),
    theme.mixins.create(fullWidth, (n) => (n ? { width: '100%' } : {})),
    theme.mixins.create(justify, (n) => (n ? { justifyContent: n } : {})),
    theme.mixins.create(align, (n) => (n ? { alignItems: n } : {})),
    theme.mixins.create(direction, (n) => (n ? { flexDirection: n } : {})),
    theme.mixins.create(nowrap, (n) => {
      let flexWrap: 'nowrap' | 'wrap' | undefined;
      if (n === true) flexWrap = 'nowrap';
      if (n === false) flexWrap = 'wrap';
      return { flexWrap };
    }),
  ),
}));

export default withStyleSystem(Flex);

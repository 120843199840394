import styled from '@emotion/styled';
import withStyleSystem from 'common/withStyleSystem';
import Link from './Link';
import { paperBg } from './Paper';

const NavListItem = styled(Link)<{ active?: boolean; }>(({ theme, active }) => ({
  ...theme.font.responsiveSize(2),
  display: 'block',
  padding: theme.spacing(2),
  borderRadius: theme.radius.default,
  backgroundColor: active ? theme.palette.background[paperBg.strong][0] : undefined,
  '&:hover': {
    backgroundColor: theme.palette.background[paperBg.strong][0],
  },
  ['svg']: {
    verticalAlign: 'baseline',
  },
}));

export default withStyleSystem(NavListItem);

import styled from '@emotion/styled';
import { FontWeight, FontSizeIndex, ColorKey } from 'common/theme';
import withStyleSystem from 'common/withStyleSystem';
import React from 'react';

type Variant = 'h1' | 'h2' | 'h3' | 'body1' | 'body2' | 'body3';

type VariantProps = {
  element: 'div' | 'h1' | 'h2' | 'h3' | 'h4' | 'p' | 'span';
  fontSize: FontSizeIndex;
  fontWeight: FontWeight;
};

export const variants: { [variant in Variant]: VariantProps } = {
  h1: {
    element: 'h1',
    fontSize: 5,
    fontWeight: 'bold',
  },
  h2: {
    element: 'h2',
    fontSize: 4,
    fontWeight: 'demibold',
  },
  h3: {
    element: 'h3',
    fontSize: 3,
    fontWeight: 'medium',
  },
  body1: {
    element: 'p',
    fontSize: 2,
    fontWeight: 'regular',
  },
  body2: {
    element: 'p',
    fontSize: 1,
    fontWeight: 'regular',
  },
  body3: {
    element: 'span',
    fontSize: 0,
    fontWeight: 'regular',
  },
};

export type TextColor = 'default' | 'weak' | 'info' | 'success' | 'error' | 'black' | 'weakSuccess' | 'weaker';

export type TypographyProps = {
  variant?: Variant,
  color?: TextColor,
  weight?: FontWeight,
  english?: boolean;
  children?: React.ReactNode,
  align?: 'center' | 'left' | 'right',
  style?: React.CSSProperties,
};

export const textColors: Record<TextColor, [ColorKey, number]> = {
  default: ['foreground', 900],
  weak: ['foreground', 100],
  weaker: ['foreground', 500],
  info: ['info', 900],
  success: ['success', 900],
  error: ['warning', 900],
  black: ['text', 900],
  weakSuccess: ['success', 400],
};

const Typography = withStyleSystem(styled.span<TypographyProps>(({ theme, align, variant = 'body1', color = 'default', weight, english }) => {
  const { fontSize, fontWeight } = variants[variant];
  const [colorKey, intensity] = textColors[color];

  return ({
    fontFamily: theme.font.family[english ? 'english' : 'default'],
    ...theme.font.responsiveSize(fontSize),
    textAlign: align || (english ? 'right' : undefined),
    direction: english ? 'ltr' : undefined,
    fontWeight: theme.font.weight[weight || fontWeight],
    color: color && `${theme.palette[colorKey][intensity][0]} !important`,
    margin: 0,
  });
}));

const Text = ({ variant = 'body1', ...rest }: Parameters<typeof Typography>[0]) => {
  const { element } = variants[variant];
  return (
    <Typography as={element} variant={variant} {...rest} />
  );
};

export default Text;

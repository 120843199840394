import styled from '@emotion/styled';

const Ellipsis = styled.div(() => ({
  maxWidth: '100%',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));

export default Ellipsis;

import React, { forwardRef, useEffect, useRef } from 'react';
import { Box, Input, Label } from './styles';
import ErrorMessage from './ErrorMessage';
import styled from '@emotion/styled';
import { useForkRef, ValidationError } from './utils';
import PasswordInput from './PasswordInput';
import Collapse from './Collapse';
import { useTheme } from './CacheProvider';

const Wrapper = styled(Box)<{ fullWidth?: boolean; }>(({ fullWidth }) => ({
  width: fullWidth ? '100%' : undefined,
}));

export type TextFieldProps = {
  name: string,
  type?: string,
  fullWidth?: boolean,
  autoFocus?: boolean,
  label?: string,
  error?: string | null | ValidationError,
  color?: 'dark' | 'light' | 'semiDark'
} & React.ComponentPropsWithoutRef<typeof Input>;

const InputComponent = forwardRef<HTMLInputElement, TextFieldProps & { type: string; }>(
  function InputComponent({ type, color = 'semiDark', ...rest }, ref) {
    switch (type) {
      case 'password':
        return <PasswordInput {...rest} ref={ref} />;
      default:
        return <Input {...rest} type={type} ref={ref} color={color} />;
    }
  },
);

const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(function TextField({
  label, type = 'text', error, color, fullWidth, autoFocus, m, mx, my, mt, mb, mr, ml, ...rest
}, ref) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleRef = useForkRef<HTMLInputElement>(inputRef, ref);
  const margins = { m, mx, my, mt, mb, mr, ml };
  const id = rest.name;
  const { currentTheme } = useTheme();
  useEffect(() => {
    if (autoFocus)
      inputRef.current?.focus();
  }, [autoFocus]);

  return (
    <Wrapper {...margins} fullWidth={fullWidth}>
      {label && <Label htmlFor={id} mb={1}>{label}</Label>}

      <InputComponent
        type={type}
        id={id}
        ref={handleRef}
        m={0}
        color={color || currentTheme === 'dark' ? 'semiDark' : 'light'}
        {...rest}
      />

      <Collapse open={!!error}>
        <ErrorMessage mt={1} field={label ?? rest?.placeholder} message={error} />
      </Collapse>
    </Wrapper>
  );
});

export default TextField;

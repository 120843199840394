import styled from '@emotion/styled';
import React, { useState } from 'react';
import Popper, { PopperProps } from './Popper';
import { Button } from './styles';

const MenuPaper = styled(Popper)(() => ({
  maxHeight: '100vh',
  overflowY: 'auto',
}));

const Menu = React.forwardRef<HTMLDivElement, Omit<PopperProps, 'onClickAway'> & { onClose?(): void, open: boolean, }>(function Menu({
  open, anchor, children, onClose: handleClose, ...rest
}, ref) {
  return (
    <MenuPaper
      anchor={anchor}
      radius="default"
      showArrow
      p={2}
      open={open}
      onClickAway={handleClose}
      ref={ref}
      {...rest}
    >
      {children}
    </MenuPaper>
  );
});

export default Menu;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MenuExample = () => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);
  return (
    <div>
      <Button onClick={() => setOpen(true)} ref={setReferenceElement}>
        لنگر
      </Button>
      <Menu open={open} onClose={() => setOpen(false)} anchor={referenceElement}>
        منو
      </Menu>
    </div>
  );
};

import React, { MouseEvent } from 'react';
import { useAgent } from '../CacheProvider';
import { Flex } from '../styles';
import { Container, DownloadButton, ElevatorButton, Menu } from './Components';
import useScrollTop from '../utils/useScrollTop';

export default function StaticMiniFooter() {
  const { isTvAgent } = useAgent();
  const { supportScroll, scrollToTop } = useScrollTop();

  const handleScrollToTop = (e: MouseEvent) => {
    e.stopPropagation();
    if (supportScroll) window.scrollTo({ top: 0, behavior: 'smooth' });
    else scrollToTop();
  };

  return (
    <Container show={true} isTvAgent={isTvAgent}>
      <Flex p={2} justify="space-between" align="center">
        <Flex justify="space-between" align="center" nowrap>
          <ElevatorButton ml={{ xs: 2, md: 3 }} mr="-.25rem" onClick={handleScrollToTop} />
          <Menu />
        </Flex>
        <Flex>
          <DownloadButton className="desktop-only" />
        </Flex>
      </Flex>
    </Container>
  );
}

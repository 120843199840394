import styled from '@emotion/styled';
import withStyleSystem from 'common/withStyleSystem';
import { mainBg } from './Main';

const CardBody = styled.div(({ theme }) => {
  const [bgColor, color] = theme.palette.background[mainBg];
  const backgroundColor = theme.rgba(bgColor, .8);

  return ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor,
    color,
    opacity: 0,
    transition: 'opacity .3s ease',
    padding: theme.spacing(1),
    '&:hover, &:active, &:focus': {
      'a &': {
        opacity: 1,
      },
    },
  });
});

export default withStyleSystem(CardBody);

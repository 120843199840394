import Carousel from './Carousel';
import ContentCard from './ContentCard';
import { __t } from './i18n';
import { Paper, Row, Text, Tag, Link, Flex } from './styles';
import styled from '@emotion/styled';
import { Content } from './apiService';
import { globalPadding } from './Layout/Layout';
import { paperBg } from './styles/Paper';

const PromotedVideosWrapper = styled.div(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export default function GlobalSearchResult({ result }) {
  const promoted = result?.promoted_videos;
  const movies = result?.videos?.filter((item) => item.type === 'single_video');
  const series = result?.videos?.filter((item) => item.type === 'series');
  const artist_carousel = result?.widgets?.find((item) => item.type === 'artist_carousel');
  const artists = artist_carousel?.configuration?.items;

  const noresults = !movies?.length && !series?.length && !artists?.length;

  return (
    <Paper px={globalPadding} pb={{ md: 4 }} borderRadius="medium">
      {promoted?.length > 0 && (
        <PromotedVideosWrapper>
          <Carousel m={0} mb={4} background={paperBg.main}>
            <Row cols={{ xs: 3, lg: 5, xl: 6 }}>
              {result?.promoted_videos?.map((item: Content) => (
                <ContentCard key={item.id} content={item} />
              ))}
            </Row>
          </Carousel>
        </PromotedVideosWrapper>
      )}
      {movies?.length > 0 && (
        <Flex direction="column" mb={3}>
          <Text variant="body1">{__t('content.type.movies')}</Text>
          <Row gap={0}>
            {movies?.map(({ id, short_id, slug, title }) => (
              <Tag key={id} as={Link} href={`/contents/${short_id}/${slug}`} ml={1} mb={1}>{title}</Tag>
            ))}
          </Row>
        </Flex>
      )}
      {series?.length > 0 && (
        <Flex direction="column" mb={3}>
          <Text variant="body1">{__t('content.type.tv_shows')}</Text>
          <Row gap={0}>
            {series?.map(({ id, short_id, slug, title }) => (
              <Tag key={id} as={Link} href={`/contents/${short_id}/${slug}`} ml={1} mb={1}>{title}</Tag>))}
          </Row>
        </Flex>
      )}
      {artists?.length > 0 && (
        <Flex direction="column">
          <Text variant="body1">{__t('content.artists')}</Text>
          <Row gap={0}>
            {artists?.map(({ person: { id, short_id, slug, name } }) => (
              <Tag key={id} as={Link} href={`/artists/${short_id}/${slug}`} ml={1} mb={1}>{name}</Tag>
            ))}
          </Row>
        </Flex>
      )}

      {noresults && __t('errors.noresult')}
    </Paper>
  );
}

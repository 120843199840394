import React from 'react';
import styled from '@emotion/styled';
import { responsiveProps, SpacingIndex } from 'common/theme';
import { FlexDirection, FlexJustify } from './Flex';
import withStyleSystem from 'common/withStyleSystem';
import { deepMerge } from 'common/utils';

export type Col = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'auto';
export type ResponsiveCols = responsiveProps<Col>;

export type ItemProps = {
  gap?: responsiveProps<SpacingIndex>,
  cols?: ResponsiveCols;
};

export type ListProps = {
  justify?: FlexJustify,
  direction?: FlexDirection,
  nowrap?: boolean,
} & ItemProps;

export const List = withStyleSystem(styled.ul<ListProps>(({
  theme, gap = 2, justify = 'flex-start', nowrap, direction = 'row',
}) => {
  return {
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: direction,
    flexWrap: nowrap ? 'nowrap' : 'wrap',
    ...theme.mixins.create(gap, (n) => ({
      [theme.logicalProp('margin', 'start')]: `-${theme.spacing(n)}`,
      marginBottom: `-${theme.spacing(n)}`,
    })),
    justifyContent: justify,
  };
}));

export const Item = styled.li<ItemProps>(({ theme, gap = 2, cols }) => {
  const responsiveCols = theme.mixins.create(cols, (n) => {
    if (n === 'auto')
      return ({
        minWidth: 'auto',
        maxWidth: 'none',
      });
    else if (n) {
      const w = 100 / n;
      return ({
        minWidth: `${w}%`, // `${w - (w / n / 3)}%`,
        maxWidth: `${w}%`, // `${w - (w / n / 3)}%`,
      });
    }
    return {};
  });

  return ({
    listStyleType: 'none',
    listStyleImage: 'none',
    display: 'inline-block', // IE 9 fallback
    ...deepMerge({},
      theme.mixins.create(gap, (n) => ({
        [theme.logicalProp('padding', 'start')]: theme.spacing(n),
        paddingBottom: theme.spacing(n),
      })),
      responsiveCols,
    ),
  });
});

type RowProps = Parameters<typeof List>[0];

export default function Row({ children, gap = 2, cols, ...rest }: RowProps) {
  return (
    <List gap={gap} cols={cols} {...rest}>
      {React.Children.map(children, (child, index) => (
        child ? <Item key={index} {...{ gap, cols }}>{child}</Item> : null),
      )}
    </List>
  );
}

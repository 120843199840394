
import styled from '@emotion/styled';
import { InputColor, inputColorMap } from './Input';

const InputGroup = styled.div<{ color?: InputColor }>(({ theme, color = 'light' }) => {
  const { fg, bg } = inputColorMap[color];

  return ({
    position: 'relative',
    flexGrow: 1,
    '& input': {
      width: '100%',
      paddingLeft: theme.spacing(7),
      color: fg,
      backgroundColor: bg,
    },
    '& button': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      left: theme.spacing(2),
      color: fg,
      backgroundColor: bg,
      '&:hover': {
        backgroundColor: theme.rgba(fg, .15),
      },
      '&:disabled': {
        pointerEvents: 'none',
      },
    },
  });
});

export default InputGroup;

import React from 'react';
import { Flag, Icon } from './styles';
import { IconImdb, IconThumbsUpFilled } from './icons';
import styled from '@emotion/styled';

const FlagWrapper = styled(Flag)(({ theme, contained }) => ({
  fontWeight: theme.font.weight.black,
  padding: contained ? '.25em .375em 0' : '.25em 0 0',
  [`${Icon}`]: {
    width: 'max-content',
    fontSize: '.8em',
    height: '1.1em',
    '& svg': {
      marginTop: -1,
      width: 'auto',
    },
  },
}));

export type RatingProps = {
  rate: number;
  type?: string;
  contained?: boolean;
} & Parameters<typeof FlagWrapper>[0];

const rateIconMap = {
  rate: <IconThumbsUpFilled />,
  imdb: <IconImdb />,
};

export default function Rating({ rate, type = 'rate', contained, ...rest }: RatingProps) {
  return (
    <FlagWrapper contained={contained} {...rest}>
      <Icon ml={1}>{rateIconMap[type]}</Icon>
      {formatValue(type, rate)}
    </FlagWrapper>
  );
}

function formatValue(type: string, value: number) {
  switch (type) {
    case 'imdb':
      return Math.floor(value) / 10;
    case 'rate':
      return `%${Math.round(value)}`;
  }
}

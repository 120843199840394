import * as React from 'react';
import styled from '@emotion/styled';
import { useForkRef } from './utils';
import { CSSTransition } from 'react-transition-group';

const CollapseContainer = styled.div<{ timeout?: number, open?: boolean, fullWidth?: boolean; }>(({ timeout = 250, fullWidth }) => ({
  transition: `height ${timeout}ms ease`,
  overflow: 'hidden',
  width: fullWidth ? '100%' : undefined,
  '&.enter-done': {
    overflow: 'visible',
  },
}));

const Wrapper = styled.div({ display: 'flex' });
const WrapperInner = styled.div({ width: '100%' });

type CollapseProps = {
  open?: boolean,
  timeout?: number,
  collapsedHeight?: number,
  children?: React.ReactNode,
  className?: string,
  fullWidth?: boolean,
  unmountOnExit?: boolean,
};

const Collapse = React.forwardRef<HTMLDivElement, CollapseProps>(function Collapse(
  { open = false, timeout = 250, children, collapsedHeight = 0, className, fullWidth, unmountOnExit }, ref,
) {
  const [initialOpen] = React.useState(open);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const handleRef = useForkRef<HTMLDivElement>(containerRef, ref);
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const handleEnter = () => {
    if (containerRef.current)
      containerRef.current.style.height = collapsedHeight + 'px';
  };

  const handleEntering = () => {
    if (containerRef.current && wrapperRef.current)
      containerRef.current.style.height = wrapperRef.current.clientHeight + 'px';
  };

  const handleEntered = () => {
    if (containerRef.current)
      containerRef.current.style.height = 'auto';
  };

  return (
    <CSSTransition
      in={open}
      timeout={timeout}
      onEnter={handleEnter}
      onEntering={handleEntering}
      onEntered={handleEntered}
      onExit={handleEntering}
      onExiting={handleEnter}
      unmountOnExit={unmountOnExit}
    >
      {(status) => (
        <CollapseContainer
          fullWidth={fullWidth}
          className={className}
          ref={handleRef}
          timeout={timeout}
          hidden={status === 'exited' && !collapsedHeight}
          style={{
            height: initialOpen ? 'auto' : collapsedHeight,
            minHeight: collapsedHeight,
            transitionDuration: timeout + 'ms',
          }}
        >
          <Wrapper ref={wrapperRef}>
            <WrapperInner>
              {children}
            </WrapperInner>
          </Wrapper>
        </CollapseContainer>
      )}
    </CSSTransition>
  );
});

export default Collapse;

import { CSSObject } from '@emotion/serialize';
import theme from './theme';

export type Direction = 'rtl' | 'ltr';

export type PaletteColor = {
  900: Color,
} & Partial<Record<ColorIntensity, Color>>;
export type Palette = Record<ColorKey, PaletteColor>

export type ColorIntensity = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
export type HslColor = `hsl(${number}, ${number}%, ${number}%)`;
export type ColorKey =
  'primary'
  | 'success'
  | 'info'
  | 'warning'
  | 'foreground'
  | 'background'
  | 'accent'
  | 'sweet'
  | 'text'
  | 'lightWarning';
export type Color = [HslColor, HslColor]

export type BreakpointKeys = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type Breakpoints = {
  [breakpoint in BreakpointKeys]: number
} & {
  up: (key: number | BreakpointKeys) => string,
  down: (key: number | BreakpointKeys) => string,
  between: (min: number | BreakpointKeys, max: number | BreakpointKeys) => string
};

export type BreakPointsOnly = {
  [breakpoint in BreakpointKeys]?: string
}

export type FontSizeIndex = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type FontWeight = 'light' | 'regular' | 'medium' | 'demibold' | 'bold' | 'black';
export type FontFamily = 'default' | 'english';
export type LineHeight = 'loose' | 'normal' | 'compact';

export type Font = {
  family: { [family in FontFamily]: string },
  weight: { [weight in FontWeight]: number },
  lineHeight: Record<LineHeight, number>,
  size: (value: FontSizeIndex) => string,
  responsiveSize: (value: FontSizeIndex) => Mixin,
};
export type Radius = {
  default: string,
  medium: string,
  pill: string,
  full: string
};
export type InputSize = 'small' | 'default';

export type Mixin = CSSObject;
export type responsiveProps<T = any> = { [key in BreakpointKeys]?: T } | T;
export type responsivePropsMap<T = any> = (x: T, y: BreakpointKeys) => Mixin;
export type Mixins = {
  [key: string]: Mixin | ((...values: any) => Mixin),
  focus: Mixin,
  create: <T = any>(props: responsiveProps<T>, calllback: responsivePropsMap<T>) => Mixin,
};
export type LogicalPropFunc = (prop: string, side: 'start' | 'end') => string;

export type SpacingIndex = 0 | 0.5 | 1 | 1.5 | 2 | 2.5 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export const zIndex = {
  systemMessage: 1600,
  appBar: 1100,
  drawer: 1200,
  snackbar: 1250,
  modal: 1200,
  tooltip: 1500,
} as const;

export type ThemeType = typeof theme;

declare module '@emotion/react' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Theme extends ThemeType {
  }
}

import { Flex, Icon, IconButton, Link, Text, Divider, Box, Image } from '../styles';
import { __t } from '../i18n';
import styled from '@emotion/styled';
import SocialLinks from '../SocialLinks';
import { IconDevices, IconTop, IconFooterImage } from '../icons';
import { SpacingProps } from '../withStyleSystem';
import { globalPadding } from '../Layout/Layout';
import { useRouter } from 'next/router';
import { FC, HTMLAttributeReferrerPolicy, useEffect, useState } from 'react';
import { footerBg } from './Components';
import { actionChangeTheme, useAgent, useDispatch } from '../CacheProvider';
import useScrollTop from '../utils/useScrollTop';
import theme from '../theme';

const Footer = () => {
  const { isTvAgent } = useAgent();
  const { pathname } = useRouter();
  return <Container id="app-footer" px={globalPadding} py={{ xs: 4, md: 2 }} isTvAgent={isTvAgent}>
    <Flex
      direction={{ xs: 'column', md: 'row' }}
      align={{ md: 'baseline' }}
      justify={{ sm: 'space-between' }}
      mt={{ md: 3 }}
      mb={{ md: 6 }}
    >
      <Brand mb={{ xs: 6, md: 0 }}/>
      <Downloads mb={{ xs: 6, md: 0 }}/>
      <Menu mb={{ xs: 6, md: 0 }}/>
    </Flex>

    <Flex justify="space-between">
      <Elevator/>
      <SocialLinks/>
    </Flex>

    <Divider my={{ xs: 6, md: 3 }}/>
    <Flex>
      <Copyright/>
      {pathname && (pathname === '/contact-us' || pathname === '/') && <TrustLinks/>}
    </Flex>
  </Container>;
};

// ----------
// SUB-COMPONENTS
// ----------

function Elevator() {
  const { supportScroll, scrollToTop } = useScrollTop();

  const handleScrollToTop = (e) => {
    e.stopPropagation();
    if (supportScroll) window.scrollTo({ top: 0, behavior: 'smooth' });
    else scrollToTop();
  };

  return (
    <IconButton p={1} onClick={handleScrollToTop} style={{ border: '1px solid' }}>
      <Icon cursor="pointer">
        <IconTop/>
      </Icon>
    </IconButton>
  );
}

const Downloads = (props: SpacingProps) => (
  <DownloadsWrapper {...props}>
    <Link href="/apps">
      <Icon ml={1} style={{ fontSize: '2em' }}>
        <IconDevices/>
      </Icon>
      {__t('footer.apps.fulltext')}
    </Link>
  </DownloadsWrapper>
);

const Brand = (props: SpacingProps) => <BrandLink href="https://www.filmnet.ir" {...props}>
  <BrandImage/>
</BrandLink>;

const Menu = (props: SpacingProps) => (
  <MenuWrapper justify="space-around" {...props} nowrap={false}>
    {menuItems.map(([title, href]) => <MenuItem key={href} title={title} href={href}/>)}
  </MenuWrapper>
);

const menuItems = [
  ['common.faq', '/faq'],
  ['footer.contact_us', '/contact-us'],
  ['footer.about_filmnet', '/about'],
  ['footer.rules', '/rules'],
];

const MenuItem = ({ href, title }: { href: string; title: string }) => (
  <MenuItemWrapper mb={{ xs: 3, md: 0 }}>
    <Link href={href}>{__t(title)}</Link>
  </MenuItemWrapper>
);

const Copyright = () => (
  <Text variant="body3" as="p" color="weak" style={{ pointerEvents: 'none' }}>
    {__t('footer.copyright')}
  </Text>
);

const TrustLinks = () => (
  <Flex nowrap justify="center" align="center" mr="auto">
    {/* <TrustLink
      name="etehadiye"
      src="/static/images/footer/etehadiye.svg"
      href="https://ecunion.ir/verify/filmnet.ir?token=44021905b8fd6ba7448f"
      width={43}
    /> */}
    <TrustLink
      name="neshane_sabt"
      src="/static/images/footer/neshane_sabt.svg"
      href="https://logo.samandehi.ir/Verify.aspx?id=21247&p=uiwkrfthuiwkaodsjyoe"
    />
    <TrustLink
      name="enamad"
      src="https://Trustseal.eNamad.ir/logo.aspx?id=210637&amp;Code=8mOMB6YjDXV5HH4C159R"
      href="https://trustseal.enamad.ir/?id=210637&amp;Code=8mOMB6YjDXV5HH4C159R"
      width={'44px'}
      id="8mOMB6YjDXV5HH4C159R"
      imageReferrerPolicy="origin"
      linkReferrerPolicy="origin"
    />
  </Flex>
);

const TrustLink = ({ name, width, src, href, id, imageReferrerPolicy, linkReferrerPolicy }: {
    name: string; width?: string; src: string; href: string,
    id?: string | undefined,
    imageReferrerPolicy?: HTMLAttributeReferrerPolicy | undefined,
    linkReferrerPolicy?: HTMLAttributeReferrerPolicy | undefined
}) => (
  <Box mx={1}>
    <a key={name} href={href} target="blank" referrerPolicy={linkReferrerPolicy ?? undefined}>
      <Image alt={name} src={src} width={width || '48px'} height="auto" id={id ?? undefined}
        referrerPolicy={imageReferrerPolicy ?? undefined}/>
    </a>
  </Box>
);

// ----------
// STYLES
// ----------

const Container = styled(Box)<{ isTvAgent?: boolean }>(({ theme, isTvAgent }) => ({
  marginBottom: isTvAgent ? '6rem' : 0,
  color: theme.palette.background[footerBg][1],
  backgroundColor: theme.palette.background[footerBg][0],
  [theme.breakpoints.down('lg')]: {
    marginBottom: isTvAgent ? '4rem' : 0,
  },
}));

const DownloadsWrapper = styled(Text)(({ theme }) => ({
  fontSize: '0.75rem',
  order: 1,
  [theme.breakpoints.up('md')]: {
    fontSize: '0.875rem',
  },
}));

const MenuWrapper = styled(Flex)(({ theme }) => ({
  width: '20rem',
  [theme.breakpoints.up('sm')]: {
    width: '50%',
  },
}));

const MenuItemWrapper = styled(Box)(({ theme }) => ({
  fontSize: '.75rem',
  [theme.breakpoints.down('md')]: {
    minWidth: '50%',
    maxWidth: '50%',
  },
}));

const BrandLink = styled(Link)(({ theme }) => ({
  display: 'inline-block',
  marginLeft: theme.spacing(1),
}));

export const BrandImage: FC<{ className?: string; width?: string; height?: string; }> = (props) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [mdBreakpoint, setMdBreakpoint] = useState(false);

  useEffect(() => {
    setMdBreakpoint(Number(theme.breakpoints.md) > Number(window.innerWidth));
  }, []);

  useEffect(() => {
    if (router.pathname === '/packages' || router.asPath.includes('scNnlM') || router.asPath.includes('tickets') || router.asPath.includes('dd-agreement') || router.asPath.includes('payment')) {
      dispatch(actionChangeTheme('light'));
    } else {
      dispatch(actionChangeTheme('dark'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <IconFooterImage {...props} style={{
    width: mdBreakpoint && '7.5rem',
    height: mdBreakpoint && '1.5rem',
  }} />;
};

export default Footer;

import styled from '@emotion/styled';
import React, { MouseEvent, useState } from 'react';
import { __t } from '../i18n';
import { Link, IconButton, Icon, Flex, Box, Text } from '../styles';
import { IconTopArrow, IconDotsVertical, IconDevices, IconClose } from '../icons';
import ClickAwayListener from 'react-click-away-listener';
import SocialLinks from '../SocialLinks';
import { headerHeight } from '../Header';
import { SpacingProps } from '../withStyleSystem';

export const footerLinks = [
  {
    label: 'footer.apps',
    href: '/apps',
    className: 'mobile-only',
  },
  {
    label: 'common.faq',
    href: '/faq',
    className: '',
  },
  {
    label: 'footer.contact_us',
    href: '/contact-us',
    className: 'desktop-only',
  },
  {
    label: 'footer.rules',
    href: '/rules',
    className: '',
  },
];

export const Menu = () => (
  <MenuContainer>
    {footerLinks.map((item) => (
      <Text variant="body3" as="div" ml={1} mr={1} key={item.label} className={item.className}>
        <Link href={item.href} onClick={(e) => e.stopPropagation()}>
          {__t(item.label)}
        </Link>
      </Text>
    ))}
    <SocialLinksToggle />
  </MenuContainer>
);

export const ElevatorButton = ({ onClick: handleClick, ...spacing }: CustomButtonProps) => (
  <Box {...spacing}>
    <IconButton onClick={handleClick}>
      <Icon cursor='pointer'>
        <IconTopArrow />
      </Icon>
    </IconButton>
  </Box>
);

export const CloseButton = ({ ...spacing }: CustomButtonProps) => {
  return (
    <CloseButtonWrapper {...spacing}>
      <Icon m={0} cursor="pointer">
        <IconClose />
      </Icon>
    </CloseButtonWrapper>
  );
};

export const MoreButton = ({ onClick: handleClick, ...spacing }: CustomButtonProps) => (
  <IconButton onClick={handleClick} {...spacing}>
    <Icon cursor="pointer">
      <IconDotsVertical />
    </Icon>
  </IconButton>
);

export const DownloadButton = ({ ...spacing }: CustomButtonProps) => (
  <Link href="/apps" {...spacing}>
    <Icon ml={1} size="large" cursor="pointer">
      <IconDevices />
    </Icon>
    {__t('footer.apps.fulltext')}
  </Link>
);

export const SocialLinksToggle = () => {
  const [show, setShow] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setShow(false)}>
      <div style={{ position: 'relative' }} className="desktop-only">
        <SocialTransition show={show}>
          <SocialLinks />
        </SocialTransition>
        <Text variant="body3" as="div" ml={1} mr={1}>
          <Link
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setShow(true);
            }}
          >
            {__t('footer.socials')}
          </Link>
        </Text>
      </div>
    </ClickAwayListener>
  );
};

export const SocialTransition = styled(Box)<{ show: boolean }>(({ theme, show }) => ({
  backgroundColor: theme.palette.background[800][0],
  position: 'absolute',
  right: 0,
  top: '50%',
  transform: 'translateY(-50%)',
  opacity: show ? 1 : 0,
  pointerEvents: show ? 'auto' : 'none',
  transition: 'opacity .3s ease',
}));

export interface LinksContainerType {
  showElevator: boolean;
}

export interface CustomButtonProps extends SpacingProps {
  onClick?: (e?: MouseEvent) => void;
  className?: string;
}

export const Container = styled(Box)<{ show: boolean, isTvAgent?: boolean }>(({ theme, show, isTvAgent }) => ({
  position: 'fixed',
  bottom: isTvAgent ? '6rem' : 0,
  left: 0,
  right: 0,
  zIndex: theme.zIndex.drawer,
  backgroundColor: theme.palette.background[footerBg][0],
  color: theme.palette.background[footerBg][1],
  transition: 'transform .3s ease, opacity .3s ease',
  transform: `translateY(${show ? 0 : '100%'})`,
  opacity: show ? 1 : 0,
  '& .mobile-only': {
    [theme.breakpoints.up('md')]: { display: 'none' },
  },
  '& .desktop-only': {
    [theme.breakpoints.down('md')]: { display: 'none' },
  },
  [theme.breakpoints.down('lg')]: {
    bottom: isTvAgent ? '4rem' : 0,
  },
}));

export const footerBg = 600;

export const MenuContainer = styled(Flex)(() => ({
  whiteSpace: 'nowrap',
  width: '100%',
}));

export const BigFooterContainer = styled.div<{ show: boolean }>(({ show, theme }) => ({
  backgroundColor: theme.palette.background[footerBg][0],
  color: theme.palette.background[footerBg][1],
  position: 'absolute',
  top: '100%',
  width: '100%',
  maxHeight: `calc(100vh - ${headerHeight})`,
  overflowY: 'auto',
  overflowX: 'hidden',
  zIndex: 1,
  transition: 'transform .5s ease, opacity .3s ease',
  transform: `translateY(${show ? '-100%' : 0})`,
  opacity: show ? 1 : 0,
}));

export const CloseButtonWrapper = styled(Box)(() => ({
  pointerEvents: 'none',
  position: 'absolute',
  top: 0,
  left: 0,
  fontSize: '1rem',
}));

export const ElevatorContainer = styled(Flex)<LinksContainerType>(({ showElevator }) => ({
  whiteSpace: 'nowrap',
  transform: showElevator ? 'translateX(0)' : 'translateX(2.075rem)',
  transition: 'transform 0.3s ease',
}));

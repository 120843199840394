import '@emotion/react';
import palette from './paletteDark';
import {
  Breakpoints, Font, Direction,
  SpacingIndex, Radius, LogicalPropFunc, Mixins,
  BreakpointKeys, zIndex,
} from './types';
export { default as staticColors } from './colors';

const direction: Direction = 'rtl';

const breakpoints: Breakpoints = {
  xs: 480,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
  up: (key) => `@media (min-width: ${breakpoints[key] || key}px)`,
  down: (key) => `@media (max-width: ${(breakpoints[key] || key) - 0.05}px)`,
  between: (min, max) => `@media (min-width: ${breakpoints[min] || min}px) and (max-width: ${breakpoints[max] || max}px)`,
};

const rgba = (color: string, opacity: number) => {
  return color?.replace(/hsl/, 'hsla').replace(/\)$/, `, ${opacity})`);
};

const mixins: Mixins = {
  focus: {}, // { boxShadow: `0 0 0 0.125rem ${palette.text.main}` },
  create: (props, callback) => {
    let responsiveMixin: any = {};
    if (typeof props === 'object')
      Object.entries(props).forEach(([breakpoint, arg]: [BreakpointKeys, any]) => {
        if (breakpoint === 'xs') {
          responsiveMixin = callback(arg, breakpoint);
        } else {
          responsiveMixin[theme.breakpoints.up(breakpoint)] = callback(arg, breakpoint);
        }
      });
    else
      responsiveMixin = callback(props, 'xs');
    return responsiveMixin;
  },
};

const fontSizes = [0.5, 0.6, 0.85, 1, 1.33, 2.2, 3.8];
const responsiveFontSizes = {
  xs: [0.625, 0.750, 0.875, 1.000, 1.125, 1.250, 1.500],
  md: [0.700, 0.800, 1.000, 1.000, 1.250, 1.500, 2.000],
  lg: [0.750, 0.875, 1.000, 1.125, 1.500, 1.875, 3.000],
};

const font: Font = {
  family: {
    default: 'dana, sans-serif',
    english: 'Roboto, sans-serif',
  },
  weight: {
    light: 300,
    regular: 400,
    medium: 500,
    demibold: 600,
    bold: 700,
    black: 900,
  },
  lineHeight: {
    loose: 2,
    normal: 1.6,
    compact: 1,
  },
  size: (v = 1) => `${fontSizes[v]}rem`,
  responsiveSize: (v = 1) => (mixins.create({
    xs: responsiveFontSizes.xs[v] + 'rem',
    md: responsiveFontSizes.md[v] + 'rem',
    lg: responsiveFontSizes.lg[v] + 'rem',
  }, (n) => (n ? { fontSize: n } : {}))),
};

const spacings = [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 6, 8, 16];
const spacing: (...values: SpacingIndex[]) => string = (...values) => (
  values.map((v) => `${spacings[v]}rem`).join(' ')
);

const radius: Radius = { default: '.25rem', medium: '.5rem', pill: '50rem', full: '1rem' };

const logicalSides = (direction: Direction) => ({
  start: direction === 'rtl' ? 'Right' : 'Left',
  end: direction === 'rtl' ? 'Left' : 'Right',
});
const logicalProp: LogicalPropFunc = (prop, side) => (prop
  ? (prop + logicalSides(theme.direction)[side])
  : logicalSides(theme.direction)[side].toLowerCase());

const theme = {
  direction,
  breakpoints,
  palette,
  rgba,
  spacings,
  spacing,
  font,
  radius,
  logicalProp,
  mixins,
  zIndex,
};

export default theme;

import * as React from 'react';

export default function useScrollTrigger(value: number) {
  const [trigger, setTrigger] = React.useState(false);
  React.useEffect(() => {
    setTrigger(document.documentElement.scrollTop > value);
    const handleScroll = () => {
      setTrigger(document.documentElement.scrollTop > value);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [value]);
  return trigger;
}

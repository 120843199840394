import { Icon, IconButton, InputGroup } from 'common/styles';
import React, { FC, FormEvent, KeyboardEvent } from 'react';
import { __t } from './i18n';
import { IconClose, IconSearch } from './icons';
import { InputProps } from './styles/Input';
import TextField from './TextField';

type SearchInputProps = {
  onChange: (value: string) => void;
  onClose?: () => void,
  onReturn?: (value: string) => void,
  value?: string;
  placeholder?: string;
  autoFocus?: boolean;
} & InputProps;

const SearchInput: FC<SearchInputProps> = ({ value = '', onChange, onReturn, onClose, color, placeholder, inputSize = 'default', autoFocus = true }) => {
  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const target = e.target as HTMLInputElement;
      onReturn && onReturn(target.value);
    }
  };

  const handleSearch = (e: FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    if (target.value !== value) onChange(target.value);
  };

  return (
    <InputGroup color={color}>
      {value?.length > 0
        ? <IconButton onClick={() => { onChange(''); onClose && onClose(); }}><Icon cursor="pointer"><IconClose /></Icon></IconButton>
        : <IconButton disabled><Icon cursor="pointer"><IconSearch /></Icon></IconButton>
      }
      <TextField
        autoFocus={autoFocus}
        autoComplete="search"
        name="search"
        placeholder={placeholder || (__t('common.search') + '...')}
        value={value}
        onChange={(e) => handleSearch(e)}
        onKeyDown={(e) => handleKeyPress(e)}
        m={0}
        inputSize={inputSize}
      />
    </InputGroup>
  );
};

export default SearchInput;

import { __t } from 'common/i18n';

export interface EpisodeIdentifierProps {
  season?: number;
  episode?: number;
  numeric?: boolean;
}

export default function getEpisodeIdentifier({ season, episode, numeric = true }: EpisodeIdentifierProps) {
  let identifier = '';
  if (season) identifier += (
    numeric
      ? `${__t('content.type.season')} ${season}`
      : __t('content.nav.season', { number: season })
  );
  if (season && episode) identifier += ' ';
  if (episode) identifier += (
    numeric
      ? `${__t('content.type.episode')} ${episode}`
      : __t('content.nav.episode', { number: episode })
  );

  return identifier;
}

import { Category, CategoryList, Content, ContentAggregate, ContentType } from 'common/apiService';

export function getGenres(categories?: CategoryList[], limit?: number): Category[] | undefined {
  return categories?.find((item) => item.type === 'genre')?.items.slice(0, limit);
}

export function getGenreTitles(categories?: CategoryList[], limit?: number): string | undefined {
  const genres = getGenres(categories, limit);
  return genres
    ?.map(({ title }) => title)
    .join('، ')
    .trim();
}

export const getContentUrl = ({ short_id, slug }: { short_id: string; slug: string }) => `/contents/${short_id}/${slug}`;

export const getPlayableId = ({ id, type, seasons, episodes }: Content) => {
  // console.table({ id, title, type, seasons: seasons?.length, episodes: episodes?.length });

  if (type === 'single_video' || type === 'episode') return id;

  const seriesTypes: Content['type'][] = ['series', 'season_of_series', 'episode'];
  if (seriesTypes.includes(type)) {
    let playableContentId: string | undefined = undefined;

    if (type === 'season_of_series') playableContentId = episodes?.[0]?.id;
    if (type === 'series') playableContentId = seasons?.[0].episodes?.[0]?.id;

    return playableContentId;
  }
};

export const getFirstEpisode = ({ type, seasons, contentId }: { contentId: string; type: Content['type']; seasons?: ContentAggregate['series']['seasons'] }) => {
  if (type === 'season_of_series') return seasons?.find(({ id }) => id === contentId)?.episodes?.[0];
  if (type === 'series') return seasons?.[0]?.episodes?.[0];
};

export function getFlagEnums(sum: number): number[] {
  const keys: number[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (let c = 1; c <= sum; c *= 2) {
    const f = sum & c;
    if (f !== 0) keys.push(f);
  }

  return keys;
}

export const inspectFlags = (flags: string) => ({
  bookmarked: flags.includes('bookmark'),
  playable: flags.includes('playable'),
  hasAccess: flags.includes('has_access'),
  free: flags.includes('traffic_free'),
  isNew: flags.includes('latest'),
  isForignIP: flags.includes('iran_access'),
  selected: flags.includes('selected'),
  comingSoon: flags.includes('coming_soon'),
  hasTicket: flags.includes('content_has_ticket'),
  isCinema: flags.includes('cinema_online'),
  hasSpecificCarrie: flags.includes('carrier_specific'),
  isAudioBook: flags.includes('audio_book'),
  ageRestricted: flags.includes('age_restricted'),
});

export const getContentType = (type: ContentType): 'movie' | 'tv_show' | 'bundle' | 'none' => {
  switch (type) {
    case 'single_video':
      return 'movie';
    case 'series':
    case 'episode':
    case 'season_of_series':
      return 'tv_show';
    case 'video_content_list':
      return 'bundle';
    default:
      return 'none';
  }
};

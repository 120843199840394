import fa from './fa.json';
import en from './en.json';
import numberToPersianWords from 'common/utils/numberToPersianWords';

export const languageOptions = {
  fa: {
    key: 'fa',
    locale: 'fa-IR',
    dictionary: fa,
    name: 'فارسی',
    dir: 'rtl',
    numberToWords: numberToPersianWords,
    dateFormat: 'jYYYY/jMM/jDD',
    longDateFormat: 'ddd jDD jMMMM jYYYY',
  },
  en: {
    key: 'en',
    locale: 'en-US',
    dictionary: en,
    name: 'English',
    dir: 'ltr',
    numberToWords: (x: number) => String(x),
    dateFormat: 'MM/DD/YYYY',
    longDateFormat: 'ddd DD MMM YYYY',
  },
} as const;

export type LanguageKey = keyof typeof languageOptions;
export type LanguageOption = typeof languageOptions[LanguageKey];
export type LanguageListener = (language: LanguageOption) => void;
export type MessageId = keyof typeof languageOptions['fa']['dictionary'];

const LanguageManager = (function () {
  let currentLanguage: LanguageKey = 'fa';

  const listeners: LanguageListener[] = [];
  const addListener = (fn: LanguageListener) => { listeners.push(fn); };
  const removeListener = (fn: LanguageListener) => {
    const index = listeners.indexOf(fn);
    if (index) {
      listeners.splice(index, 1);
    }
  };

  const set = (newLanguage: LanguageKey) => {
    currentLanguage = newLanguage;
    listeners.forEach((fn) => {
      fn(languageOptions[currentLanguage]);
    });
    return currentLanguage;
  };

  const get = () => {
    return languageOptions[currentLanguage];
  };

  function translate(id: MessageId, values?: Record<string, string | number>): string;
  function translate(id: string, values?: Record<string, string | number>): string;
  function translate(id: string, values?: Record<string, string | number>): string {
    const language = languageOptions[currentLanguage];
    let message: string = language.dictionary[id];
    if (!message) return id;
    if (values) {
      Object.entries(values).forEach(([key, value]) => {
        message = message.replace(RegExp(String.raw`{${key}}`, 'g'), String(value));
        message = message.replace(RegExp(String.raw`{${key}, number}`, 'g'), language.numberToWords(+value));
        message = message.replace(RegExp(String.raw`{${key}, number_nth}`, 'g'), language.numberToWords(+value, { nth: true }));
      });
    }
    return message;
  }
  return {
    set, get, addListener, removeListener, translate,
  } as const;
})();

export default LanguageManager;

export const __t = LanguageManager.translate;

import NavListItem from './styles/NavListItem';
import { EllipsisText, Flex, Icon } from 'common/styles';
import withStyleSystem from './withStyleSystem';
import { ReactNode } from 'react';
import { __t } from './i18n';
import { useRouter } from 'next/router';
import styled from '@emotion/styled';

export type NavLink = {
  label: string,
  icon?: ReactNode,
  href: string,
  labelFiled?: string,
};

const NavList = ({ links }: { links: NavLink[] }) => {
  const { pathname } = useRouter();

  return (
    <Flex direction="column" fullWidth>
      {
        links.map(({ label, icon, href, labelFiled }) => (
          <NavListItemWrapper mb="1px" key={label} href={href} active={pathname === href}>
            {icon && <Icon size="large" ml={1} cursor="pointer">{icon}</Icon>}
            {__t(label)}
            {labelFiled && <EllipsisText variant='body3' mr={1} color='weaker'>({labelFiled})</EllipsisText>}
          </NavListItemWrapper>
        ))
      }
    </Flex>
  );
};

export default withStyleSystem(NavList);

const NavListItemWrapper = styled(NavListItem)(({
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
}));

export default function IconTwitter() {
  return (
    <svg width="21" height="14" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.05859 1.38563C2.05859 1.38563 6.42618 3.81628 9.92574 5.44863C9.16673 4.16012 11.2924 -1.37343 16.3005 2.16372C17.237 1.80977 18.2236 1.38029 18.8563 1.12806"
        stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
      />
      <path
        d="M19.9026 2.8252L17.2341 4.60148C17.2341 4.60148 17.79 9.48666 13.2312 13.539C8.67232 17.5913 2.0563 14.9821 1 14.0387C3.44623 13.3724 6.61513 12.4289 6.61513 12.4289"
        stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
      />
      <path d="M4.99833 9.80859L2.53125 9.94023" stroke="currentColor" strokeLinecap="round" />
      <path d="M3.48436 6.62062L1.37891 6.0293" stroke="currentColor" strokeLinecap="round" />
    </svg>
  );
}

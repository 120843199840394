import { useUser } from 'common/CacheProvider';
import { __t } from 'common/i18n';
import { Button, Link, Text } from 'common/styles';
import Tooltip from '../Tooltip';
import { useEffect, useState } from 'react';
import theme from '../theme';

export default function SubscriptionButton() {
  const { hasSubscription, subscriptionRemainingDays } = useUser();
  const [openTooltip, setOpenTooltip] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const subscriptionButtonMessage = (!hasSubscription || subscriptionRemainingDays <= 0) ? 'subscription.buy' : 'subscription.extend';

  useEffect(() => {
    handleTooltip();
    window.addEventListener('resize', handleTooltip);
    return () => {
      window.removeEventListener('resize', handleTooltip);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleTooltip = () => {
    setOpenTooltip(Number(theme.breakpoints.md) > Number(window.innerWidth));
  };

  return (
    <Button
      ref={setReferenceElement}
      variant="primaryB"
      as={Link}
      href="/packages"
      inputSize="small"
      mx={{ xs: 2, md: 3 }}
    >
      <Tooltip
        disableMobileHover={openTooltip}
        anchor={referenceElement}
        placement="bottom"
        offsetY={10}
        type="denseSuccess"
        pInline='1rem'
        pBlock='0.3rem'
      >
        <Text variant="body3">
          {__t('common.discount.tooltip')}
        </Text>
      </Tooltip>
      {__t(subscriptionButtonMessage)}
    </Button>
  );
}

import styled from '@emotion/styled';

type CardProps = {
  orientation: 'vertical' | 'horizontal' | 'square',
  href?: string,
  block?: boolean,
  minWidth?: string,
};

const getRatio = (o: CardProps['orientation']) => {
  switch (o) {
    case 'vertical': return '140%';
    case 'horizontal': return '56%';
    case 'square': return '100%';
  }
};

const Card = styled.div<CardProps>(({ theme, minWidth = '6rem', orientation = 'vertical' }) => ({
  lineHeight: theme.font.lineHeight.normal,
  display: 'block',
  position: 'relative',
  paddingBottom: getRatio(orientation),
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: theme.radius.medium,
  overflow: 'hidden',
  minWidth,
  backgroundColor: theme.palette.background[500][0],
}));

export default Card;

import styled from '@emotion/styled';
import theme, { ColorKey } from 'common/theme';
import withStyleSystem from 'common/withStyleSystem';
import { CSSProperties } from 'react';

export const sizes = {
  small: 0.625,
  semiSmall: 0.8,
  inherit: 1,
  large: 1.5,
  semiLarge: 2,
  medium: 3,
  semiMedium: 4,
  big: 4,
  huge: 6,
  xLarge: 6.8,
};

export type Size = 'small' | 'semiSmall' | 'inherit' | 'large' | 'semiLarge' | 'medium' | 'semiMedium' | 'big' | 'huge' | 'xLarge';

const Icon = withStyleSystem(styled.span<{ size?: Size; color?: ColorKey, cursor?: CSSProperties['cursor'] }>(({ size = 'inherit', color, cursor }) => ({
  cursor: cursor ? cursor : 'default',
  display: 'inline-block',
  fontSize: `${sizes[size]}em`,
  color: color ? theme.palette[color][900][0] : undefined,
  verticalAlign: 'middle',
  width: '1em',
  height: '1em',
  lineHeight: 1,
  margin: '0 2px',
  '& svg, & img': {
    width: '100%',
    height: '100%',
  },
})));

export default Icon;

import styled from '@emotion/styled';
import Icon from './Icon';

const RotatableIcon = styled(Icon)<{ trigger?: boolean; angle?: number; }>(({
  angle = 180, trigger: inProp = false,
}) => ({
  transform: inProp ? `rotate(${angle}deg)` : 'none',
  transition: 'transform 0.25s ease',
}));

export default RotatableIcon;

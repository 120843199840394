import { clientSession, User } from 'common/apiService';
import { Action, ActionLoginPayload, GlobalState, Theme, GlobalProfile } from './types';
import * as Sentry from '@sentry/nextjs';

export const actionLogin = (payload: ActionLoginPayload): Action => {
  const { access_token, user } = payload;
  Sentry.setUser({ id: user?.id, username: String(user?.msisdn) });
  clientSession.defaults.headers.Authorization = `Bearer ${access_token}`;
  return { type: 'LOGIN', payload };
};

export const actionUpdateProfile = (payload: GlobalProfile): Action => {
  return { type: 'UPDATE_PROFILE', payload };
};

export const actionLogout = (): Action => {
  clientSession.defaults.headers.Authorization = '';
  if (localStorage) {
    localStorage.removeItem('subscriptionAlertClosedTime');
    localStorage.removeItem('lscache-selected_pined_profile');
  }
  Sentry.setUser(null);
  return { type: 'LOGOUT' };
};

export const actionUpdateUser = (payload: User): Action => {
  return { type: 'UPDATE_USER', payload };
};

export const actionChangeTheme = (payload: Theme): Action => {
  return { type: 'UPDATE_THEME', payload };
};

export const actionLoad = (payload: GlobalState): Action => {
  return { type: 'LOAD', payload };
};

export const actionUpdateAgent = (payload: boolean): Action => {
  return { type: 'UPDATE_AGENT', payload };
};

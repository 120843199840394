import { useEffect, useState } from 'react';
import Router from 'next/router';
import LinearProgress from './styles/LinearProgress';
import styled from '@emotion/styled';
import { BackDrop } from './styles';
import _ from 'lodash';

const Container = styled.div(() => ({
  position: 'fixed',
  width: '100%',
  top: 0,
  right: 0,
  left: 0,
  zIndex: 10000,
}));

const RouteChangeLoading = () => {
  const [loading, setLoading] = useState(false);
  const debounce = _.debounce(() => {
    setLoading(true);
  }, 300);
  useEffect(() => {
    const handleChangeComplete = () => {
      debounce.cancel();
      setLoading(false);
    };
    Router.events.on('routeChangeStart', debounce);
    Router.events.on('routeChangeComplete', handleChangeComplete);
    Router.events.on('routeChangeError', handleChangeComplete);
    return () => {
      Router.events.off('routeChangeStart', debounce);
      Router.events.off('routeChangeComplete', handleChangeComplete);
      Router.events.off('routeChangeError', handleChangeComplete);
    };
  }, []);

  if (!loading) return null;

  return (
    <BackDrop passive>
      <Container>
        <LinearProgress />
      </Container>
    </BackDrop>
  );
};

export default RouteChangeLoading;

import styled from '@emotion/styled';
import withStyleSystem from 'common/withStyleSystem';

const Flag = styled.span<{ contained?: boolean }>(({ theme, contained }) => ({
  display: 'inline-block',
  padding: contained ? '.125em .375em' : '.125em 0',
  backgroundColor: contained ? theme.rgba(theme.palette.foreground[100][0], .3) : undefined,
  borderRadius: theme.radius.default,
  ...theme.font.responsiveSize(1),
  border: 'none',
  whiteSpace: 'nowrap',
  lineHeight: theme.font.lineHeight.normal,
}));

export default withStyleSystem(Flag);

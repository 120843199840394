import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import Icon, { Size } from './Icon';
import { IconSpinner } from './../icons';
import { ColorKey } from 'common/theme';
import { SpacingProps } from 'common/withStyleSystem/withSpacingStyles';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const SpinnerIcon = styled(Icon)<{ block?: boolean }>(({ block }) => ({
  display: block ? 'block' : undefined,
  transform: 'scale(1.4)',
  '& svg': {
    animation: `${spin} .5s linear infinite`,
  },
}));

type SpinnerProps = {
  color?: ColorKey;
  size?: Size;
  block?: boolean;
};

export default function Spinner({ size, block, color, ...rest }: SpinnerProps & SpacingProps) {
  return (
    <SpinnerIcon size={size} color={color} block={block} {...rest}>
      <IconSpinner />
    </SpinnerIcon>
  );
}

export const WrapperSpinner = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  minHeight: '50vh',
  alignItems: 'center',
}));

import styled from '@emotion/styled';
import { staticColors, InputSize } from 'common/theme';
import withStyleSystem from 'common/withStyleSystem';
import { CSSProperties } from 'react';
import { variants } from './Text';

export interface InputProps {
  align?: CSSProperties['textAlign'];
  letterSpacing?: 'normal' | 'large';
  inputSize?: InputSize;
  color?: InputColor;
  rows?: number;
  holderSize?: string;
}

export type InputColor = 'light' | 'dark' | 'semiDark';

export const inputSizeStyles = {
  default: {
    padding: '.5em 1em',
    fontSize: variants.body1.fontSize,
  },
  small: {
    padding: '.25em 1em',
    fontSize: variants.body2.fontSize,
  },
};

export const inputColorMap = {
  light: {
    fg: staticColors.black,
    bg: staticColors.white,
  },
  dark: {
    fg: staticColors.white,
    bg: staticColors.black,
  },
  semiDark: {
    fg: staticColors.white,
    bg: staticColors.semiDark,
  },
};

const Input = styled.input<InputProps>(({ theme, align, letterSpacing, color = 'light', inputSize = 'default' }) => {
  const { padding, fontSize } = inputSizeStyles[inputSize];
  const { bg, fg } = inputColorMap[color];
  const [borderColor] = theme.palette.foreground[100];
  return {
    ...theme.font.responsiveSize(fontSize),
    padding,
    width: '100%',
    backgroundColor: bg,
    color: fg,
    borderRadius: theme.radius.medium,
    textAlign: align,
    letterSpacing: letterSpacing === 'large' ? '0.5em' : undefined,
    margin: theme.spacing(1, 0),
    border: '1px solid',
    borderColor: color === 'semiDark' ? bg : borderColor,
    '&:focus-visible': {
      outline: 'none',
    },
    '&[type="number"], &[type="tel"]': {
      direction: 'ltr',
    },
    '&:disabled': {
      color: fg,
      backgroundColor: theme.rgba(bg, 0.25),
    },
  };
});

export default withStyleSystem(Input);
